@import "media-breakpoints";

div.content-section {
  position: relative;
  margin: 0 auto;
  //overflow: hidden;

  @media (min-width: map-get($media-breakpoints, md)) {
    & {
      max-width: map-get($media-breakpoints, sm);
      max-width: 500px;
      border-left: solid 1px var(--ion-color-light-shade);
      border-right: solid 1px var(--ion-color-light-shade);

      &:last-of-type {
        border-bottom: solid 1px var(--ion-border-color);
        margin-bottom: 40px;
      }
    }
  }

  &.sticky {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .content-section {
    border: none;
    margin-bottom: 0;
  }

  .border-top {
    border-top: solid 1px var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  }

  .border-bottom {
    border-bottom: solid 1px var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
  }
}

// Можно обвернуть этим несколько контент секшенов, чтобы они хорошо скрорились под табом. mh - min-height
.mh {
  position: relative;
  overflow: hidden;

  .md & {
    min-height: calc(100% - 104px);
  }

  .ios & {
    min-height: calc(100% - 76px);
  }

  &.search {
    .md & {
      min-height: calc(100% - 162px);
    }

    .ios & {
      min-height: calc(100% - 136px);
    }
  }
}


