@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "media-breakpoints";

html {
  //--ion-border-color: var(--ion-color-step-150, rgba(0, 0, 0, 0.13));
  --ion-border-color: var(--ion-color-light-shade);
  --ion-color-success: #1ABE79;
}

ion-menu {
  --width: calc(min(100% - 56px, 320px));

  ion-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    min-height: 100%;
  }

  //ion-item.name {
  //  --padding-top: 24px;
  //
  //  &.first {
  //    --padding-top: 0px;
  //  }
  //}

  div.separator {
    height: 1px;
    margin: 10px 0px;
    background-color: #D9D9D9;
  }

  div.expander {
    min-height: 120px;
    flex-grow: 1;
  }
}

ion-header {

  background-color: var(--ion-toolbar-background);

  ion-toolbar {
    @media (min-width: map-get($media-breakpoints, md)) {
      & {
        max-width: map-get($media-breakpoints, sm);
        max-width: 500px; // Как у контент секшена
        margin-left: auto;
        margin-right: auto;
        right: 8px; // scroll bar of content balancing, 8px scroll bar size in chrome
      }
    }
  }
}

ion-content {

  @media (min-width: map-get($media-breakpoints, md)) {
    position: relative;

    //&::before {
    //  content: "";
    //  background: url("../assets/backgrounds/pattern.jpg");
    //  opacity: 10%;
    //  background-size: 330px;
    //  top: 0;
    //  left: 0;
    //  bottom: 0;
    //  right: 0;
    //  position: absolute;
    //  z-index: -2;
    //}
    //
    //&::after {
    //  content: "";
    //  background: var(--ion-color-light-tint);
    //  opacity: 50%;
    //  top: 0;
    //  left: 0;
    //  bottom: 0;
    //  right: 0;
    //  position: absolute;
    //  z-index: -1;
    //
    //  @media (prefers-color-scheme: dark) {
    //    opacity: 50%;
    //  }
    //}
  }


}

ion-searchbar {
  --background: var(--ion-item-background) !important;

  @media (prefers-color-scheme: dark) {
    --background: var(--ion-color-light-tint) !important;
  }

}

ion-list {

  ion-label h2 {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .list-md {

    padding-bottom: 0px; // Чтобы было видно нижний бордер у последнего айтема, а так хотел 0

    &.border {

      ion-item:first-of-type {
        --border-width: 1px 0 1px 0;
      }

      ion-item:last-of-type {
        //--border-width: 1px 1px 1px 1px;
      }
    }

    &.no-last-border {
      ion-item {
        &:last-child {
          --border-width: 0 0 0 0;
        }
      }
    }
  }
}

ion-chip {
  user-select: none;
}

ion-alert {
  .alert-message {
    & > *:first-child {
      margin-top: 0;
    }

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  &.center {
    text-align: center;

    .alert-head {
      text-align: center;

      h2 {
        display: block;
      }
    }

    .alert-button-group {
      justify-content: center;

      button {
        margin: 0;
      }
    }
  }
}

ion-avatar {
  overflow: hidden;
}

ion-infinite-scroll, ion-infinite-scroll-content {
  height: 1px !important;
  margin-bottom: -1px;
  z-index: -1;
}

form.form-meta {
  ion-list {
    padding-bottom: 0;
  }

  ion-item {
    --highlight-background: var(--ion-border-color);
    --show-full-highlight: 0;

    ion-label {
      color: var(--color) !important;
    }

    ion-input, ion-textarea {
      caret-color: #000000;
    }
  }

  &.ng-submitted {
    ion-item {
      &.ion-invalid, &.lib-invalid {
        --ion-border-color: var(--highlight-color-invalid);
        --border-width: 0 0 2px 0;
      }
    }
  }
}

ion-modal {
  &.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);;
  }

  &.date-picker {
    --width: 290px;
    --height: 390px;
    --border-radius: 8px;
  }

  &.time-picker {
    --width: 290px;
    --height: 252px;
    --border-radius: 8px;
  }

  &:not(.date-picker, .time-picker) {
    @media only screen and (min-width: 768px) {
      --width: 100%;
      --height: 100%;
    }
  }
}

ion-input {
  //Для скрытия внутреннего инпута. Может быть полезно если у нас инпут с датой или временем.1
  &.clickable {
    cursor: pointer;

    input {
      display: none !important;
    }
  }
}

ion-textarea {
  min-height: 78px;

  textarea {
    min-height: 78px;
  }
}

ion-virtual-scroll {
  contain: initial;
}

