@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");
div.content-section {
  position: relative;
  margin: 0 auto;
}
@media (min-width: 769px) {
  div.content-section {
    max-width: 576px;
    max-width: 500px;
    border-left: solid 1px var(--ion-color-light-shade);
    border-right: solid 1px var(--ion-color-light-shade);
  }
  div.content-section:last-of-type {
    border-bottom: solid 1px var(--ion-border-color);
    margin-bottom: 40px;
  }
}
div.content-section.sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}
div.content-section .content-section {
  border: none;
  margin-bottom: 0;
}
div.content-section .border-top {
  border-top: solid 1px var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
}
div.content-section .border-bottom {
  border-bottom: solid 1px var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-150, rgba(0, 0, 0, 0.13))));
}

.mh {
  position: relative;
  overflow: hidden;
}
.md .mh {
  min-height: calc(100% - 104px);
}
.ios .mh {
  min-height: calc(100% - 76px);
}
.md .mh.search {
  min-height: calc(100% - 162px);
}
.ios .mh.search {
  min-height: calc(100% - 136px);
}

.bwf {
  overflow: hidden;
}
.bwf > * {
  margin: 16px 16px;
}
.bwf > h2 {
  margin-top: 24px;
}
.bwf > h2 + .data {
  margin-top: 0;
}
.bwf .message {
  display: block;
}
.bwf .message > ion-item {
  --padding-start: 0;
  --padding-end: 0;
  --ion-safe-area-left: 0;
  --ion-safe-area-right: 0;
  --inner-padding-start: 0;
  --inner-padding-end: 0;
}
.bwf .message > .author {
  display: grid;
  grid-template-rows: minmax(max-content, 0) minmax(max-content, 0);
  grid-template-columns: max-content auto max-content;
  margin-bottom: 12px;
}
.bwf .message > .author lib-avatar {
  grid-row: 1/span 2;
  grid-column: 1;
  align-self: center;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}
.bwf .message > .author h5 {
  grid-row: 1;
  grid-column: 2;
  align-self: end;
  margin: 0;
}
.bwf .message > .author ion-note {
  grid-row: 2;
  grid-column: 2;
  align-self: start;
  margin: 0;
}
.bwf .message > .author ion-buttons {
  grid-row: 1/span 2;
  grid-column: 3;
}
.bwf .message > .buttons {
  margin: 0;
}
.bwf .message > .buttons a {
  text-decoration: underline;
}
.bwf .message > .content {
  display: block;
  overflow: hidden;
  padding: 0 16px;
  margin-bottom: 8px;
  border-radius: 0 16px 16px 16px;
}
.bwf .message > .content p lib-stars {
  position: relative;
  top: 2px;
}
.bwf .message > .content app-images-set {
  margin: 16px 0;
}
.bwf .message > .content.primary {
  background-color: rgba(var(--ion-color-primary-rgb), 0.08);
  color: var(--ion-text-color);
}
.bwf .message > .content.success {
  background-color: rgba(var(--ion-color-success-rgb), 0.08);
  color: var(--ion-color-success);
}
.bwf .message > .content.canceled {
  background-color: rgba(var(--ion-color-danger-rgb), 0.08);
  color: var(--ion-color-danger);
}
.bwf .message:last-child {
  margin-bottom: 24px;
}
.bwf .divider {
  border-top: 2px;
  border-top-style: dashed;
  border-top-color: var(--ion-border-color);
  margin: 0;
}
.bwf > .data {
  font-size: 15px;
}
.bwf > .data > .data-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 12px 0;
}
.bwf > .data > .data-row ion-icon {
  margin-right: 8px;
  flex-shrink: 0;
}
.bwf > .services {
  font-size: 15px;
  margin: 24px 16px;
}
.bwf > .services > .row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.bwf > .services > .row > span:first-child {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.bwf > .services > .row > span:first-child:after {
  content: "......................................................................................................................................................";
  flex-grow: 1;
  width: 10px;
  margin-left: 4px;
  margin-right: 4px;
  white-space: nowrap;
  overflow: hidden;
}
.bwf > .services > .row > span:last-child {
  flex-shrink: 0;
}
.bwf > .notifications ion-chip {
  margin-inline: 0px;
  margin-right: 8px;
}
.bwf > .buttons {
  margin: 16px;
}

html {
  --ion-border-color: var(--ion-color-light-shade);
  --ion-color-success: #1ABE79;
}

ion-menu {
  --width: calc(min(100% - 56px, 320px));
}
ion-menu ion-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 100%;
}
ion-menu div.separator {
  height: 1px;
  margin: 10px 0px;
  background-color: #D9D9D9;
}
ion-menu div.expander {
  min-height: 120px;
  flex-grow: 1;
}

ion-header {
  background-color: var(--ion-toolbar-background);
}
@media (min-width: 769px) {
  ion-header ion-toolbar {
    max-width: 576px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    right: 8px;
  }
}

@media (min-width: 769px) {
  ion-content {
    position: relative;
  }
}

ion-searchbar {
  --background: var(--ion-item-background) !important;
}
@media (prefers-color-scheme: dark) {
  ion-searchbar {
    --background: var(--ion-color-light-tint) !important;
  }
}

ion-list ion-label h2 {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
ion-list .list-md {
  padding-bottom: 0px;
}
ion-list .list-md.border ion-item:first-of-type {
  --border-width: 1px 0 1px 0;
}
ion-list .list-md.no-last-border ion-item:last-child {
  --border-width: 0 0 0 0;
}

ion-chip {
  user-select: none;
}

ion-alert .alert-message > *:first-child {
  margin-top: 0;
}
ion-alert .alert-message > *:last-child {
  margin-bottom: 0;
}
ion-alert.center {
  text-align: center;
}
ion-alert.center .alert-head {
  text-align: center;
}
ion-alert.center .alert-head h2 {
  display: block;
}
ion-alert.center .alert-button-group {
  justify-content: center;
}
ion-alert.center .alert-button-group button {
  margin: 0;
}

ion-avatar {
  overflow: hidden;
}

ion-infinite-scroll, ion-infinite-scroll-content {
  height: 1px !important;
  margin-bottom: -1px;
  z-index: -1;
}

form.form-meta ion-list {
  padding-bottom: 0;
}
form.form-meta ion-item {
  --highlight-background: var(--ion-border-color);
  --show-full-highlight: 0;
}
form.form-meta ion-item ion-label {
  color: var(--color) !important;
}
form.form-meta ion-item ion-input, form.form-meta ion-item ion-textarea {
  caret-color: #000000;
}
form.form-meta.ng-submitted ion-item.ion-invalid, form.form-meta.ng-submitted ion-item.lib-invalid {
  --ion-border-color: var(--highlight-color-invalid);
  --border-width: 0 0 2px 0;
}

ion-modal.modal-default:not(.overlay-hidden) ~ ion-modal.modal-default {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32);
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
ion-modal.date-picker {
  --width: 290px;
  --height: 390px;
  --border-radius: 8px;
}
ion-modal.time-picker {
  --width: 290px;
  --height: 252px;
  --border-radius: 8px;
}
@media only screen and (min-width: 768px) {
  ion-modal:not(.date-picker, .time-picker) {
    --width: 100%;
    --height: 100%;
  }
}

ion-input.clickable {
  cursor: pointer;
}
ion-input.clickable input {
  display: none !important;
}

ion-textarea {
  min-height: 78px;
}
ion-textarea textarea {
  min-height: 78px;
}

ion-virtual-scroll {
  contain: initial;
}

h1, ion-title, h2, h3 {
  margin-top: 32px;
  margin-bottom: 8px;
  font-weight: 500;
}

*:not(ion-label) > h2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
*:not(ion-label) > h2 > span {
  flex-grow: 1;
}
*:not(ion-label) > h2 > ion-buttons {
  height: 28px;
}

p {
  line-height: 1.4em;
}

a {
  cursor: pointer;
}

ul {
  padding-inline-start: 20px;
}
ul li {
  margin: 8px 0;
}

.outlet-container {
  height: 100%;
}

/* Generated by ParaType (http://www.paratype.com)*/
/* Font PT Root UI: Copyright ? ParaType, 2018. All rights reserved.*/
@font-face {
  font-family: "PTRootUIWeb";
  src: url("PT-Root-UI_Light.woff2") format("woff2"), url("PT-Root-UI_Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "PTRootUIWeb";
  src: url("PT-Root-UI_Regular.woff2") format("woff2"), url("PT-Root-UI_Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "PTRootUIWeb";
  src: url("PT-Root-UI_Medium.woff2") format("woff2"), url("PT-Root-UI_Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "PTRootUIWeb";
  src: url("PT-Root-UI_Bold.woff2") format("woff2"), url("PT-Root-UI_Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
html {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  --padding-start: 15px;
  --inner-padding-end: 15px;
}
html ol {
  padding-left: 20px;
}

ion-app {
  background: #F6F6F6;
}
ion-app > div.circles {
  display: none;
}
@media (max-width: 768px) {
  ion-app > div.circles {
    display: none;
  }
}
ion-app > div.circles div:nth-of-type(1) {
  width: 300px;
  height: 300px;
  background: #FCE9F2;
  position: absolute;
  top: -87px;
  left: -59px;
  border-radius: 999px;
}
ion-app > div.circles div:nth-of-type(2) {
  width: 50px;
  height: 50px;
  background: #FCE9F2;
  position: absolute;
  top: 232px;
  left: 54px;
  border-radius: 999px;
}
ion-app > div.circles div:nth-of-type(3) {
  width: 157px;
  height: 157px;
  background: #FCE9F2;
  position: absolute;
  top: 408px;
  left: -78px;
  border-radius: 999px;
}
ion-app > div.circles div:nth-of-type(4) {
  width: 157px;
  height: 157px;
  background: #FCE9F2;
  position: absolute;
  top: 725px;
  left: -78px;
  border-radius: 999px;
}
ion-app > div.circles div:nth-of-type(5) {
  width: 100px;
  height: 100px;
  background: #FCE9F2;
  position: absolute;
  top: 890px;
  left: 29px;
  border-radius: 999px;
}
ion-app > div.circles div:nth-of-type(6) {
  width: 300px;
  height: 300px;
  background: #FCE9F2;
  position: absolute;
  top: -140px;
  right: 52px;
  border-radius: 999px;
}
ion-app > div.circles div:nth-of-type(7) {
  width: 90px;
  height: 90px;
  background: #FCE9F2;
  position: absolute;
  top: 149px;
  right: 40px;
  border-radius: 999px;
}
ion-app > div.circles div:nth-of-type(8) {
  width: 230px;
  height: 230px;
  background: #FCE9F2;
  position: absolute;
  top: 675px;
  right: -110px;
  border-radius: 999px;
}

@media (min-width: 769px) {
  ion-header ion-buttons:has(ion-menu-button) {
    display: none !important;
  }
}
ion-header ion-title {
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}
@media (max-width: 768px) {
  ion-header ion-title ion-searchbar.sc-ion-searchbar-ios-h {
    --border-radius: 0;
    border-radius: 5px;
    height: 30px;
    --ion-item-background: #F2F2F2;
  }
}
ion-header ion-title .toolbar-background {
  --border-width: 0;
}

ion-menu ion-menu-toggle > ion-item {
  font-family: Montserrat;
  font-weight: 500;
  color: #444444;
  font-size: 14px;
}
ion-menu ion-menu-toggle > ion-item ion-icon {
  font-size: 20px;
}

ion-content {
  --ion-background-color: #F6F6F6;
  --ion-font-family: $font-main;
}

div.side-menu {
  position: absolute;
  top: 0px;
  right: 50%;
  transform: translateX(-260px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 14px;
  color: #848484;
  cursor: pointer;
  user-select: none;
  padding: 0 20px;
  --color: red;
}
div.side-menu > div.logo {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  user-select: none;
  height: 53px;
  margin-bottom: 23px;
}
div.side-menu > div.logo > img {
  height: 40px;
}
div.side-menu > div.logo > .slogan {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  font-size: 12px;
  color: #848484;
  width: 120px;
}
div.side-menu > div.item {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 8px 17px;
  border-radius: 5px;
}
div.side-menu > div.item ion-icon {
  font-size: 20px;
}
div.side-menu > div.item > span {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 133px;
}
div.side-menu > div.item:hover {
  background: #ECECEC;
}
div.side-menu > div.item.active {
  color: #DA4B90;
}
div.side-menu > div.item.active:before {
  background: #DA4B90;
}
div.side-menu > div.item.inactive {
  cursor: auto;
}
div.side-menu > div.item.inactive:hover {
  background: initial;
}
div.side-menu > div.separator {
  height: 1px;
  background: #e3e3e3;
  margin: 10px 0;
  margin-left: 50px;
  margin-right: 35px;
}
@media (max-width: 768px) {
  div.side-menu {
    display: none;
  }
}

div.content-section {
  border-left: none;
  border-right: none;
}
div.content-section:not(:first-child) .card.transparent:first-child, div.content-section:not(:first-child) .transparent.question:first-child, div.content-section:not(:first-child) div.transparent.promo:first-child, div.content-section:not(:first-child) div.transparent.windows:first-child, div.content-section:not(:first-child) .transparent.price-list-item:first-child {
  padding-top: 0;
}
@media (max-width: 768px) {
  div.content-section:not(:first-child) .card.transparent-mobile:first-child, div.content-section:not(:first-child) .transparent-mobile.question:first-child, div.content-section:not(:first-child) div.transparent-mobile.promo:first-child, div.content-section:not(:first-child) div.transparent-mobile.windows:first-child, div.content-section:not(:first-child) .transparent-mobile.price-list-item:first-child {
    padding-top: 0;
  }
}
@media (min-width: 769px) {
  div.content-section:first-child:has(.card:first-child:not(.transparent), .question:first-child:not(.transparent), div.promo:first-child:not(.transparent), div.windows:first-child:not(.transparent), .price-list-item:first-child:not(.transparent)), lib-refresher + div.content-section:has(.card:first-child:not(.transparent), .question:first-child:not(.transparent), div.promo:first-child:not(.transparent), div.windows:first-child:not(.transparent), .price-list-item:first-child:not(.transparent)) {
    margin-top: 20px;
  }
}
@media (max-width: 768px) {
  div.content-section {
    margin-top: 0;
    margin-bottom: 0;
  }
  div.content-section * > form > .cards-list:first-child > .card:first-child, div.content-section * > form > .cards-list:first-child > .question:first-child, div.content-section * > form > .cards-list:first-child > div.promo:first-child, div.content-section * > form > .cards-list:first-child > div.windows:first-child, div.content-section * > form > .cards-list:first-child > .price-list-item:first-child, div.content-section:first-of-type > form > .cards-list:first-child > .card:first-child, div.content-section:first-of-type > form > .cards-list:first-child > .question:first-child, div.content-section:first-of-type > form > .cards-list:first-child > div.promo:first-child, div.content-section:first-of-type > form > .cards-list:first-child > div.windows:first-child, div.content-section:first-of-type > form > .cards-list:first-child > .price-list-item:first-child, div.content-section:first-of-type > .cards-list:first-child > .card:first-child, div.content-section:first-of-type > .cards-list:first-child > .question:first-child, div.content-section:first-of-type > .cards-list:first-child > div.promo:first-child, div.content-section:first-of-type > .cards-list:first-child > div.windows:first-child, div.content-section:first-of-type > .cards-list:first-child > .price-list-item:first-child, div.content-section:first-of-type > .card:first-child, div.content-section:first-of-type > .question:first-child, div.content-section:first-of-type > div.promo:first-child, div.content-section:first-of-type > div.windows:first-child, div.content-section:first-of-type > .price-list-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
@media (min-width: 769px) {
  div.content-section {
    border-bottom: none !important;
  }
}
div.content-section.sticky {
  padding: 0 !important;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 3;
}

h1, ion-title {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 8px;
}

h2 {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 8px;
}

h3 {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 17px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 8px;
}

h4 {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 8px;
}

.online::after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #1ABE79;
  border-radius: 50%;
}

.offline::after {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #F73E32;
  border-radius: 50%;
}

ion-header.header-md {
  box-shadow: 0px 1px 3px rgba(169, 169, 169, 0.25);
}
ion-header.header-md::after {
  display: none;
}

ion-title {
  color: unset;
  margin: 0;
}

ion-button {
  font-size: 16px;
  --border-radius: 10px;
  --box-shadow: 0px 1px 3px rgba(169, 169, 169, 0.25);
  text-transform: none;
  letter-spacing: normal;
  font-weight: 700;
  margin: 0;
}
ion-button.button-outline {
  margin: 0;
  --border-width: 1px;
  --border-color: #d6d6d6;
  --background-hover: #d6d6d6;
  --background-hover-opacity: 1;
  --box-shadow: 0;
  color: #1B1B1B;
}
ion-button.button-small {
  font-size: 13px;
}
ion-button.button-has-icon-only {
  --box-shadow: none;
}
ion-button[shape=circle] {
  --border-radius: 999px !important;
  --border-color: #EFEFEF;
  --border-style: solid;
  --border-width: 1px;
  width: 56px;
  height: 56px;
  color: #1B1B1B;
}

ion-icon {
  --ionicon-stroke-width: 40px;
}

ion-accordion-group.accordion-group-expand-inset ion-accordion {
  box-shadow: none;
  color: #444444;
  border: solid 1px #EFEFEF;
}
ion-accordion-group.accordion-group-expand-inset ion-accordion:not(.accordion-expanding):not(.accordion-expanded) {
  margin-top: -1px;
}
ion-accordion-group.accordion-group-expand-inset ion-accordion:first-of-type {
  margin-top: 0px;
}
ion-accordion-group.accordion-group-expand-inset ion-accordion:last-of-type {
  margin-bottom: 0px;
}
ion-accordion-group.accordion-group-expand-inset ion-accordion.accordion-expanded ion-item {
  --background: #ECECEC;
}
ion-accordion-group.accordion-group-expand-inset ion-accordion ion-item[slot=header] {
  --border-style: none;
  font-size: 15px;
  font-weight: 500;
  color: #444444;
}
ion-accordion-group.accordion-group-expand-inset ion-accordion ion-item[slot=header] > div.content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 10px 0;
}
ion-accordion-group.accordion-group-expand-inset ion-accordion ion-item[slot=header]:has(ion-buttons) {
  --inner-padding-end: 5px;
}
ion-accordion-group.accordion-group-expand-inset ion-accordion ion-item[slot=header] ion-icon {
  font-size: 15px;
  color: #848484;
}

ion-list:not(.classic) {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 7px;
  padding: 0;
  padding-top: 2px;
  contain: initial;
}
ion-list:not(.classic).list-md {
  background: none;
}
ion-list:not(.classic).list-ios {
  background: none;
}
ion-list:not(.classic).colored-primary {
  --ion-item-background: #FCF3F7;
}
ion-list:not(.classic).colored-primary ion-item {
  border: none;
  box-shadow: none;
  --background: #FCF3F7 !important;
}
ion-list:not(.classic).colored-secondary {
  --ion-item-background: #FFF6DE !important;
}
ion-list:not(.classic).colored-secondary ion-item {
  border: none;
  box-shadow: none;
  --background: #FFF6DE !important;
}
ion-list:not(.classic).transparent {
  gap: 20px;
}
ion-list:not(.classic).transparent ion-item {
  --background: none;
  margin: -10px -10px;
  --padding-start: 10px;
}
ion-list:not(.classic).transparent ion-item ion-avatar:has(ion-icon) {
  background: #EFEFEF;
}
ion-list:not(.classic) ion-reorder-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 7px;
}
ion-list:not(.classic) ion-virtual-scroll .item-container:not(:first-child) {
  padding-top: 7px;
}
ion-list:not(.classic) ion-item {
  --border-radius: 10px;
  --background: #f7f7f7;
  --border-style: none;
  --min-height: none;
  --padding-start: 15px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --detail-icon-color: #848484;
  --detail-icon-font-size: 17px;
  --detail-icon-opacity: 1;
  color: #1B1B1B;
  font-size: 15px;
  font-weight: 500;
}
ion-list:not(.classic) ion-item:has(ion-buttons) {
  --inner-padding-end: 5px;
}
ion-list:not(.classic) ion-item:has(ion-buttons) ion-buttons {
  margin: 0;
}
ion-list:not(.classic) ion-item:has(ion-buttons) ion-buttons ion-button {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  width: 35px !important;
  height: 35px !important;
  margin: 0;
}
ion-list:not(.classic) ion-item:has(ion-buttons) ion-buttons ion-button ion-icon[slot=icon-only] {
  color: #444444;
  font-size: 17px !important;
}
ion-list:not(.classic) ion-item > ion-avatar, ion-list:not(.classic) ion-item > lib-avatar {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 15px;
  width: 40px;
  height: 40px;
}
ion-list:not(.classic) ion-item > ion-avatar:has(ion-icon), ion-list:not(.classic) ion-item > lib-avatar:has(ion-icon) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
ion-list:not(.classic) ion-item > ion-avatar:has(ion-icon) ion-icon, ion-list:not(.classic) ion-item > lib-avatar:has(ion-icon) ion-icon {
  font-size: 17px;
}
ion-list:not(.classic) ion-item > ion-label, ion-list:not(.classic) ion-item > div.content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0;
}
ion-list:not(.classic) ion-item > ion-label span.accent-up, ion-list:not(.classic) ion-item > div.content span.accent-up {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  color: #1B1B1B;
  line-height: 18px;
}
ion-list:not(.classic) ion-item > ion-label span.accent-down, ion-list:not(.classic) ion-item > div.content span.accent-down {
  font-family: PTRootUIWeb;
  font-weight: 400;
  font-size: 12px;
  color: #848484;
}
ion-list:not(.classic) ion-item > ion-label > *, ion-list:not(.classic) ion-item > div.content > * {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 17px;
}
ion-list:not(.classic) ion-item > ion-label > h4, ion-list:not(.classic) ion-item > div.content > h4 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 15px;
  margin: 0;
}
ion-list:not(.classic) ion-item > ion-label > h4 > *, ion-list:not(.classic) ion-item > div.content > h4 > * {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ion-list:not(.classic) ion-item > ion-label > div > *, ion-list:not(.classic) ion-item > div.content > div > * {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ion-list:not(.classic) ion-item > ion-label > p, ion-list:not(.classic) ion-item > div.content > p {
  margin: 0 !important;
  font-size: 13px;
  font-family: PTRootUIWeb;
  color: #444444;
}
ion-list:not(.classic) ion-item > ion-icon[slot=end] {
  color: #848484;
  font-size: 17px !important;
}
ion-list:not(.classic) ion-item > *[slot=end] {
  margin-left: 10px;
}
ion-list:not(.classic) ion-item.disabled ion-label, ion-list:not(.classic) ion-item.disabled img {
  opacity: 0.5;
}
ion-list:not(.classic) ion-item.colored-primary {
  --background: #FCF3F7;
  border: none;
  box-shadow: none;
}
ion-list:not(.classic) ion-item.colored-secondary {
  --background: #FFF6DE;
  border: none;
  box-shadow: none;
}
ion-list:not(.classic) ion-item.bwf-2 .date {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  color: #DA4B90;
}
ion-list:not(.classic) ion-item.bwf-2 .day:before {
  content: "|";
  position: relative;
  bottom: 1px;
  margin: 0 5px;
  font-size: 12px;
}
ion-list:not(.classic) ion-item.bwf-2 ion-button.today.online, ion-list:not(.classic) ion-item.bwf-2 ion-button.tomorrow.online, ion-list:not(.classic) ion-item.bwf-2 ion-button.online.online {
  --background: #1B9BE2;
}
ion-list:not(.classic) ion-item.bwf-2 ion-button.today.today, ion-list:not(.classic) ion-item.bwf-2 ion-button.today.tomorrow, ion-list:not(.classic) ion-item.bwf-2 ion-button.tomorrow.today, ion-list:not(.classic) ion-item.bwf-2 ion-button.tomorrow.tomorrow, ion-list:not(.classic) ion-item.bwf-2 ion-button.online.today, ion-list:not(.classic) ion-item.bwf-2 ion-button.online.tomorrow {
  --background: #1ABE79;
}
ion-list:not(.classic) ion-item.pli.colored-primary {
  background: #FFF6DE;
}
ion-list:not(.classic) ion-item.pli > ion-label ion-icon[name=checkmark] {
  color: #1ABE79;
  position: relative;
  top: 0.15em;
  --ionicon-stroke-width: 80px;
}
ion-list:not(.classic) ion-item.pli span.price {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  color: #1B1B1B;
  line-height: 17px;
}
ion-list:not(.classic) ion-item.pli span.price.old {
  color: #848484;
  font-size: 11px;
  text-decoration: line-through;
}
ion-list:not(.classic) ion-item.pli span.time {
  font-size: 13px;
  color: #444444;
}
span.price + ion-list:not(.classic) ion-item.pli span.time:before {
  content: "|";
  position: relative;
  bottom: 1px;
  margin: 0 5px;
  color: #848484;
  font-size: 12px;
}
ion-list:not(.classic) ion-item.pli span.price + span.time:before {
  content: "|";
  position: relative;
  bottom: 1px;
  margin: 0 5px;
  color: #848484;
  font-size: 12px;
}
ion-list:not(.classic) ion-item.pli span.icons:has(*):before {
  content: "|";
  position: relative;
  bottom: 1px;
  margin: 0 5px;
  color: #848484;
  font-size: 12px;
}
ion-list:not(.classic) ion-item.pli span.icons:has(*) > span {
  text-transform: uppercase;
  font-size: 13px;
  font-family: Montserrat;
  font-weight: 900;
}
ion-list:not(.classic) ion-item.pli span.icons:has(*) > ion-icon, ion-list:not(.classic) ion-item.pli span.icons:has(*) > span {
  margin: 0 4px;
  position: relative;
}
ion-list:not(.classic) ion-item.promo p.info span.discount, ion-list:not(.classic) ion-item.windows p.info span.discount, ion-list:not(.classic) ion-item.promo div.info span.discount, ion-list:not(.classic) ion-item.windows div.info span.discount {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 14px;
  font-weight: 700;
  color: #DA4B90;
}
ion-list:not(.classic) ion-item.promo p.info span.dates, ion-list:not(.classic) ion-item.windows p.info span.dates, ion-list:not(.classic) ion-item.promo div.info span.dates, ion-list:not(.classic) ion-item.windows div.info span.dates {
  position: relative;
  bottom: 1px;
  font-size: 13px;
  color: #444444;
}
ion-list:not(.classic) ion-item.promo p.info span.discount + span.dates:before, ion-list:not(.classic) ion-item.windows p.info span.discount + span.dates:before, ion-list:not(.classic) ion-item.promo div.info span.discount + span.dates:before, ion-list:not(.classic) ion-item.windows div.info span.discount + span.dates:before {
  content: "|";
  position: relative;
  bottom: 1px;
  margin: 0 5px;
  color: #848484;
}
ion-list:not(.classic) ion-item.client ion-icon[name=person-add-outline] {
  color: #1ABE79;
}
ion-list:not(.classic) ion-item.master-details p.days {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 17px;
  overflow: hidden;
  flex-wrap: wrap;
}
ion-list:not(.classic) ion-item.master-details p.days div.day {
  white-space: nowrap;
}
ion-list:not(.classic) ion-item.master-details p.days span.day:not(:first-of-type):before {
  content: "|";
  color: #848484;
  margin: 0 10px;
  position: relative;
  bottom: 1px;
}
ion-list:not(.classic) ion-item.master-booking .date {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  color: #1B1B1B;
  color: #DA4B90;
}
ion-list:not(.classic) ion-item.master-booking .item {
  font-size: 13px;
  color: #444444;
}
ion-list:not(.classic) ion-item.master-booking > ion-icon {
  margin-left: 5px;
  color: #1ABE79;
}
ion-list:not(.classic) ion-item.master-booking .two-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: initial;
  text-wrap: wrap;
}
ion-list:not(.classic) ion-item.master-window {
  --background: #ddf3ff;
}
ion-list:not(.classic) ion-item.master-window .date {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  color: #1B1B1B;
  color: #1B9BE2;
}
ion-list:not(.classic) ion-item.master-window ion-avatar {
  background: #1B9BE2;
}
ion-list:not(.classic) ion-item.master-window ion-avatar ion-icon {
  color: #FFF;
}
ion-list:not(.classic) ion-item.master-pause {
  --background: #ddf7ec;
}
ion-list:not(.classic) ion-item.master-pause .date {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 14px;
  color: #1B1B1B;
  color: #1ABE79;
}
ion-list:not(.classic) ion-item.master-pause ion-avatar {
  background: #1ABE79;
}
ion-list:not(.classic) ion-item.master-pause ion-avatar ion-icon {
  color: #FFF;
}
ion-list:not(.classic) ion-list-header {
  padding-left: 0;
  padding-right: 0;
}
ion-list:not(.classic) div.image-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding-bottom: 13px;
}
ion-list:not(.classic) div.image-item .image {
  width: 80px;
  height: 80px;
  cursor: pointer;
}
p {
  line-height: initial;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}

.chips {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
}
.chips + .chips {
  margin-top: 10px;
}

.chip {
  padding: 7px 15px;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  border-radius: 10px;
  background: #FFF;
}
.chip > div.label {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  font-size: 11px;
  color: #848484;
}
.chip.gray {
  background: #f7f7f7;
}
.chip.gray > div.label {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  font-size: 11px;
  color: #666666;
}
@media (min-width: 769px) {
  .chip:not(.gray) {
    box-shadow: 0px 1px 3px rgba(169, 169, 169, 0.25);
  }
}
@media (max-width: 768px) {
  .chip:not(.gray) {
    box-shadow: 0px 1px 3px rgba(169, 169, 169, 0.25);
  }
}
.chip > div.value {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 14px;
  color: #DA4B90;
  margin-top: -2px;
}
.chip > ion-buttons {
  grid-row: 1/3;
  justify-self: center;
  align-self: center;
  font-size: 15px;
  margin-left: 15px;
}
.chip > ion-buttons .button-has-icon-only.button-clear {
  --padding-top: 2px;
  --padding-end: 2px;
  --padding-bottom: 2px;
  --padding-start: 2px;
}
.chip > ion-buttons > ion-button {
  width: 20px !important;
  height: 20px !important;
  min-height: 20px !important;
}
.chip > ion-buttons > ion-button ion-icon {
  color: #848484;
}
.chip.empty {
  padding: 13px 15px !important;
}
.chip.empty > div.label {
  font-size: 14px;
  color: #444444;
}
.chip.empty > div.value {
  display: none;
}
.chip.empty > ion-buttons {
  display: none;
}

.data-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.data-list div.image-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  padding-bottom: 13px;
}
.data-list div.image-item .image {
  width: 80px;
  height: 80px;
  cursor: pointer;
}
.data-list div.item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  border-bottom: solid 1px #EFEFEF;
  cursor: pointer;
}
.data-list div.item div.label {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  flex-shrink: 0;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
}
.data-list div.item div.label div.sub-label {
  color: #848484;
  font-size: 12px;
}
.data-list div.item div.value {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  flex-grow: 1;
  font-size: 15px;
  font-weight: 600;
}
.data-list div.item div.value.gray {
  color: #848484;
  font-weight: 500;
}
.data-list div.item div.value.big {
  white-space: inherit;
}
.data-list div.item div.value.active {
  color: #DA4B90;
  font-weight: 500;
}

.list .items, .list ion-reorder-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;
}
.grid > .one {
  grid-column: span 6;
}
.grid > .two {
  grid-column: span 3;
}
.grid > .three {
  grid-column: span 2;
}

.cards-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  padding: 0;
  margin-bottom: 10px;
}
.cards-list.v2 {
  gap: 20px;
  margin: 20px 0;
}
.cards-list.v2 .card, .cards-list.v2 .question, .cards-list.v2 div.promo, .cards-list.v2 div.windows, .cards-list.v2 .price-list-item {
  padding-top: 0;
  padding-bottom: 0;
}

.card, .question, div.promo, div.windows, .price-list-item {
  display: block;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0px 1px 3px rgba(169, 169, 169, 0.25);
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 20px;
}
.card > *:first-child, .question > *:first-child, div.promo > *:first-child, div.windows > *:first-child, .price-list-item > *:first-child {
  margin-top: 0;
}
.card > *:last-child, .question > *:last-child, div.promo > *:last-child, div.windows > *:last-child, .price-list-item > *:last-child {
  margin-bottom: 0;
}
.card > h2, .question > h2, div.promo > h2, div.windows > h2, .price-list-item > h2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}
.card > h2 > a, .question > h2 > a, div.promo > h2 > a, div.windows > h2 > a, .price-list-item > h2 > a {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  color: #848484;
  font-size: 14px;
  text-decoration: underline;
}
.card > h2:first-child, .question > h2:first-child, div.promo > h2:first-child, div.windows > h2:first-child, .price-list-item > h2:first-child {
  margin-top: 0;
}
.card.padding, .padding.question, div.padding.promo, div.padding.windows, .padding.price-list-item {
  padding-left: 20px;
  padding-right: 20px;
}
.card.no-padding, .no-padding.question, div.no-padding.promo, div.no-padding.windows, .no-padding.price-list-item {
  padding: 0;
}
@media (max-width: 768px) {
  .card.padding-mobile, .padding-mobile.question, div.padding-mobile.promo, div.padding-mobile.windows, .padding-mobile.price-list-item {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.card.no-vertical-padding, .no-vertical-padding.question, div.no-vertical-padding.promo, div.no-vertical-padding.windows, .no-vertical-padding.price-list-item {
  padding-top: 0;
  padding-bottom: 0;
}
.card.transparent, .transparent.question, div.transparent.promo, div.transparent.windows, .transparent.price-list-item {
  cursor: initial;
  background: none;
  box-shadow: none;
  border-radius: 0;
  border: none;
  overflow: visible;
}
.transparent + .card.transparent, .transparent + .transparent.question, .transparent + div.transparent.promo, .transparent + div.transparent.windows, .transparent + .transparent.price-list-item {
  padding-top: 0;
}
@media (max-width: 768px) {
  .transparent + .card.transparent, .transparent + .transparent.question, .transparent + div.transparent.promo, .transparent + div.transparent.windows, .transparent + .transparent.price-list-item, .transparent-mobile + .card.transparent, .transparent-mobile + .transparent.question, .transparent-mobile + div.transparent.promo, .transparent-mobile + div.transparent.windows, .transparent-mobile + .transparent.price-list-item {
    padding-top: 0;
  }
}
.card.transparent > h1, .transparent.question > h1, div.transparent.promo > h1, div.transparent.windows > h1, .transparent.price-list-item > h1, .card.transparent > ion-title, .transparent.question > ion-title, div.transparent.promo > ion-title, div.transparent.windows > ion-title, .transparent.price-list-item > ion-title, .card.transparent > h2, .transparent.question > h2, div.transparent.promo > h2, div.transparent.windows > h2, .transparent.price-list-item > h2, .card.transparent > h3, .transparent.question > h3, div.transparent.promo > h3, div.transparent.windows > h3, .transparent.price-list-item > h3 {
  padding-top: 4px;
}
@media (max-width: 768px) {
  .card.transparent-mobile, .transparent-mobile.question, div.transparent-mobile.promo, div.transparent-mobile.windows, .transparent-mobile.price-list-item {
    cursor: initial;
    background: none;
    box-shadow: none;
    border-radius: 0;
    border: none;
    overflow: visible;
  }
  .transparent + .card.transparent-mobile, .transparent + .transparent-mobile.question, .transparent + div.transparent-mobile.promo, .transparent + div.transparent-mobile.windows, .transparent + .transparent-mobile.price-list-item, .transparent-mobile + .card.transparent-mobile, .transparent-mobile + .transparent-mobile.question, .transparent-mobile + div.transparent-mobile.promo, .transparent-mobile + div.transparent-mobile.windows, .transparent-mobile + .transparent-mobile.price-list-item {
    padding-top: 0;
  }
  .card.transparent-mobile > h1, .transparent-mobile.question > h1, div.transparent-mobile.promo > h1, div.transparent-mobile.windows > h1, .transparent-mobile.price-list-item > h1, .card.transparent-mobile > ion-title, .transparent-mobile.question > ion-title, div.transparent-mobile.promo > ion-title, div.transparent-mobile.windows > ion-title, .transparent-mobile.price-list-item > ion-title, .card.transparent-mobile > h2, .transparent-mobile.question > h2, div.transparent-mobile.promo > h2, div.transparent-mobile.windows > h2, .transparent-mobile.price-list-item > h2, .card.transparent-mobile > h3, .transparent-mobile.question > h3, div.transparent-mobile.promo > h3, div.transparent-mobile.windows > h3, .transparent-mobile.price-list-item > h3 {
    padding-top: 4px;
  }
}
.card.sticky, .sticky.question, div.sticky.promo, div.sticky.windows, .sticky.price-list-item {
  padding-top: 20px;
  padding-bottom: 20px;
  background: #F6F6F6;
  margin-left: -3px;
  margin-right: -3px;
}
@media (max-width: 768px) {
  .card.bottom-fixed-mobile, .bottom-fixed-mobile.question, div.bottom-fixed-mobile.promo, div.bottom-fixed-mobile.windows, .bottom-fixed-mobile.price-list-item {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}
.card ion-avatar.connection-avatar, .question ion-avatar.connection-avatar, div.promo ion-avatar.connection-avatar, div.windows ion-avatar.connection-avatar, .price-list-item ion-avatar.connection-avatar {
  background-color: #C06C84;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card ion-avatar.connection-avatar > img, .question ion-avatar.connection-avatar > img, div.promo ion-avatar.connection-avatar > img, div.windows ion-avatar.connection-avatar > img, .price-list-item ion-avatar.connection-avatar > img {
  width: 25px;
  height: 25px;
}
.card.master, .master.question, div.master.promo, div.master.windows, .master.price-list-item {
  padding: 20px;
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 16px;
  cursor: pointer;
  user-select: none;
}
.card.master div.head, .master.question div.head, div.master.promo div.head, div.master.windows div.head, .master.price-list-item div.head {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.card.master div.head :first-child, .master.question div.head :first-child, div.master.promo div.head :first-child, div.master.windows div.head :first-child, .master.price-list-item div.head :first-child {
  flex-shrink: 0;
}
.card.master div.head :last-child, .master.question div.head :last-child, div.master.promo div.head :last-child, div.master.windows div.head :last-child, .master.price-list-item div.head :last-child {
  flex-grow: 1;
  overflow: hidden;
}
.card.master div.head > ion-avatar, .master.question div.head > ion-avatar, div.master.promo div.head > ion-avatar, div.master.windows div.head > ion-avatar, .master.price-list-item div.head > ion-avatar {
  width: 70px;
  height: 70px;
}
.card.master div.head > div, .master.question div.head > div, div.master.promo div.head > div, div.master.windows div.head > div, .master.price-list-item div.head > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.card.master div.head > div h3, .master.question div.head > div h3, div.master.promo div.head > div h3, div.master.windows div.head > div h3, .master.price-list-item div.head > div h3 {
  margin: 0;
  font-size: 17px;
  font-weight: 700;
  color: #1B1B1B;
}
.card.master div.head > div p.specs, .master.question div.head > div p.specs, div.master.promo div.head > div p.specs, div.master.windows div.head > div p.specs, .master.price-list-item div.head > div p.specs {
  margin: 0;
  margin-top: 3px;
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #848484;
  font-size: 14px;
}
.card.master div.head > div p.digits, .master.question div.head > div p.digits, div.master.promo div.head > div p.digits, div.master.windows div.head > div p.digits, .master.price-list-item div.head > div p.digits {
  margin: 0;
  margin-top: 3px;
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
}
.card.master div.head > div p.digits span.digit, .master.question div.head > div p.digits span.digit, div.master.promo div.head > div p.digits span.digit, div.master.windows div.head > div p.digits span.digit, .master.price-list-item div.head > div p.digits span.digit {
  font-size: 15px;
}
.card.master div.head > div p.digits span.digit ion-icon, .master.question div.head > div p.digits span.digit ion-icon, div.master.promo div.head > div p.digits span.digit ion-icon, div.master.windows div.head > div p.digits span.digit ion-icon, .master.price-list-item div.head > div p.digits span.digit ion-icon {
  bottom: 1.5px;
}
.card.master div.head > div p.digits span.digit ion-icon[name=star], .master.question div.head > div p.digits span.digit ion-icon[name=star], div.master.promo div.head > div p.digits span.digit ion-icon[name=star], div.master.windows div.head > div p.digits span.digit ion-icon[name=star], .master.price-list-item div.head > div p.digits span.digit ion-icon[name=star] {
  bottom: 1.5px;
}
.card.master div.address, .master.question div.address, div.master.promo div.address, div.master.windows div.address, .master.price-list-item div.address {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  left: -3px;
}
.card.master div.address > ion-icon, .master.question div.address > ion-icon, div.master.promo div.address > ion-icon, div.master.windows div.address > ion-icon, .master.price-list-item div.address > ion-icon {
  flex-shrink: 0;
  font-size: 20px;
  color: #848484;
  margin-right: 8px;
}
.card.master div.address > div, .master.question div.address > div, div.master.promo div.address > div, div.master.windows div.address > div, .master.price-list-item div.address > div {
  overflow: hidden;
}
.card.master div.address div.line1, .master.question div.address div.line1, div.master.promo div.address div.line1, div.master.windows div.address div.line1, .master.price-list-item div.address div.line1 {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #444444;
}
.card.master div.address div.line2, .master.question div.address div.line2, div.master.promo div.address div.line2, div.master.windows div.address div.line2, .master.price-list-item div.address div.line2 {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  color: #848484;
}
.card.master div.text, .master.question div.text, div.master.promo div.text, div.master.windows div.text, .master.price-list-item div.text {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-size: 14px;
  color: #444444;
}
.card.master div.price-list-item, .master.question div.price-list-item, div.master.promo div.price-list-item, div.master.windows div.price-list-item, .master.price-list-item div.price-list-item {
  background: #FFF6DE;
}

.masters-list .card-container {
  padding-top: 10px;
}

.price-list-item {
  padding: 10px 15px;
  overflow: hidden;
  position: relative;
}
.price-list-item .first {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  color: #1B1B1B;
  font-size: 15px;
  font-weight: 500;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.price-list-item .second {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  margin-top: 2px;
}
.price-list-item .second .price {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 12px;
  font-weight: 600;
}
.price-list-item .second .time {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  font-size: 12px;
  color: #848484;
}
.price-list-item .second .time:before {
  content: "|";
  margin: 0 10px;
  position: relative;
  bottom: 1px;
}

div.promo, div.windows {
  padding: 10px 16px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
div.promo h3, div.windows h3 {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  color: #1B1B1B;
  font-size: 15px;
  margin: 0;
  padding: 0;
}
div.promo h4, div.windows h4 {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  color: #848484;
  font-size: 13px;
  margin: 0;
  padding: 0;
  margin-top: 2px;
}
div.promo div.discount, div.windows div.discount {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  color: #DA4B90;
  font-size: 17px;
}
div.promo div.discount, div.windows div.discount {
  position: absolute;
  bottom: 17px;
  right: 14px;
  margin: 0;
}

.windows > div {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  font-size: 12px;
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  height: 15px;
  overflow: hidden;
  flex-wrap: wrap;
}
.windows > div .window {
  white-space: nowrap;
}
.windows > div .window:not(:first-of-type):before {
  content: "|";
  color: #848484;
  margin: 0 10px;
  position: relative;
  bottom: 1px;
}

.question {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: solid 1px #EFEFEF;
  padding: 10px 15px;
}

div.digits {
  position: absolute;
  right: 0;
  bottom: -1px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.95);
  background: #FFF;
  padding: 8px 16px;
  border-radius: 10px 0 0 0;
  overflow: hidden;
  border-top: solid 1px #EFEFEF;
  border-left: solid 1px #EFEFEF;
  cursor: pointer;
  user-select: none;
}

span.digit {
  font-weight: 600;
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 13px;
}
span.digit:not(:last-of-type) {
  margin-right: 12px;
}
span.digit span.top {
  font-size: 9px;
  position: relative;
  top: -4px;
  color: var(--ion-color-step-400);
}
span.digit span.bottom {
  font-size: 9px;
  position: relative;
  bottom: 0px;
  color: var(--ion-color-step-400);
  display: none;
}
span.digit ion-icon {
  vertical-align: text-bottom;
  position: relative;
  bottom: 1px;
}
span.digit ion-icon[name=star] {
  bottom: 1px;
}
span.digit ion-text {
  position: relative;
}
span.digit.star {
  color: #E89E0E;
}
span.digit.returnability {
  color: #1ABE79;
}
span.digit.reliability {
  color: #1B9BE2;
}

ion-searchbar {
  height: 36px;
  --ion-item-background: #F2F2F2;
}
ion-searchbar.sc-ion-searchbar-ios-h {
  padding: 0;
  height: 36px;
}
ion-searchbar input.searchbar-input.sc-ion-searchbar-ios {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  font-size: 15px;
  padding-left: 40px !important;
}
ion-searchbar ion-icon.searchbar-search-icon.sc-ion-searchbar-ios {
  width: 17px;
  color: #888;
  margin: 0 8px !important;
}

div.banner {
  padding: 15px;
  background: linear-gradient(90.93deg, #DA4B90 0.8%, rgba(223, 75, 147, 0.8) 99.79%);
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 1px 3px rgba(169, 169, 169, 0.25);
}
div.banner h2 {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  color: #FFF;
  margin: 0;
  padding: 0;
  font-size: 17px;
}
div.banner h3 {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  color: #FFF;
  margin: 0;
  padding: 0;
}
div.banner p {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  font-weight: 400;
  color: #FFF;
  margin: 0;
  padding: 0;
  margin-top: 8px;
}
div.banner ion-button {
  min-height: auto;
}
div.banner.b1 {
  position: relative;
  padding-left: 80px;
  text-align: left;
}
div.banner.b1 img {
  position: absolute;
  top: 10px;
  left: 0;
  width: 64px;
}
div.banner.b2 {
  position: relative;
  padding-left: 120px;
  padding-right: 50px;
  text-align: left;
  overflow: hidden;
}
div.banner.b2 p {
  font-size: 13px;
}
div.banner.b2 img {
  position: absolute;
  top: 10px;
  left: -20px;
  max-height: unset;
  height: 200px;
  width: 120px;
}
div.banner.b2 ion-button {
  margin-top: 15px;
  --background: #ffffff;
}
div.banner.b3 {
  position: relative;
  padding-left: 120px;
  padding-right: 50px;
  text-align: left;
  overflow: hidden;
}
div.banner.b3 p {
  font-size: 13px;
}
div.banner.b3 img {
  position: absolute;
  top: 10px;
  left: -5px;
  max-height: unset;
  width: 104px;
  height: 126px;
}
div.banner.b3 ion-button {
  margin-top: 15px;
  --background: #ffffff;
}
div.banner.b4 {
  position: relative;
  text-align: left;
  overflow: hidden;
  padding-right: 50px;
}
div.banner.b4 p {
  font-size: 13px;
}
div.banner.b4 img {
  position: absolute;
  bottom: 10px;
  right: 15px;
  max-height: unset;
  width: 180px;
  height: 70px;
}
div.banner.b4 ion-button {
  margin-top: 15px;
  --background: #ffffff;
  font-size: 15px;
}

.figure-1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.figure-1 > .avatar {
  flex-shrink: 0;
}
.figure-1 > .text {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.figure-1 > .text * {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  padding: 0;
}
.figure-1 > .details {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 5px;
  border-radius: 999px;
}
.figure-1 > .details ion-icon {
  font-size: 14px;
  color: #848484;
}
.figure-1.clickable-circle {
  margin: -3px;
  padding: 3px;
  border-radius: 999px;
  margin: -10px;
  padding: 10px;
  border-radius: 10px;
}
.figure-1.clickable-circle:hover {
  background: #ECECEC;
}
.figure-1.clickable-square {
  margin: -10px;
  padding: 10px;
  border-radius: 10px;
}
.figure-1.clickable-square:hover {
  background: #ECECEC;
}
.figure-1.address > .avatar {
  width: 36px;
  height: 36px;
}
.figure-1.address > .avatar .icon-container {
  background: #F2F2F2;
  color: #444444;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.figure-1.address h4 {
  grid-row: 1;
  grid-column: 2;
  margin: 0;
}
.figure-1.address p {
  grid-row: 2;
  grid-column: 2;
  margin: 0;
  font-size: 13px;
}

.master-note {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.figure-2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.figure-2 > ion-toggle.toggle {
  flex-shrink: 0;
  padding: 0;
}
.figure-2 > div.label {
  flex-grow: 1;
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 14px;
  color: #444444;
}
.figure-2 > div.options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.figure-2 > div.options > div.current {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  font-size: 13px;
  color: #DA4B90;
}
.figure-2 > div.options > div.details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #848484;
  font-size: 13px;
}

.date-big {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 24px;
  color: #DA4B90;
  font-weight: 700;
}
.date-big .extra {
  font-size: 12px;
  color: #848484;
  font-weight: 600;
  position: relative;
  bottom: 5px;
}
.date-big .status {
  font-size: 10px;
  font-weight: 600;
  color: #FFF;
  padding: 5px 10px;
  border-radius: 999px;
}
.date-big .status.today {
  background: #1ABE79;
}
.date-big .status.online {
  background: #1B9BE2;
}
.date-big .status.done {
  background: #1ABE79;
}
.date-big .status.canceled {
  background: #F73E32;
}
.date-big .status.moved {
  background: #1B9BE2;
}
.date-big .status.circle {
  text-align: center;
  width: 17px;
  height: 17px;
  line-height: 17px;
  padding: 0 !important;
}

div.bwf-2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: visible;
}
div.bwf-2 > .header {
  display: flex;
  flex-direction: column;
}
div.bwf-2 > .header .text h4 {
  margin: 0;
}
div.bwf-2 > .separator {
  border-bottom: solid 1px #EFEFEF;
  position: relative;
}
div.bwf-2 > .separator.v2 {
  margin-left: -20px;
  margin-right: -20px;
  border-style: dashed;
  border-width: 1px;
  border-color: #EFEFEF;
}
div.bwf-2 > .separator.v2::before {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  left: -10px;
  border-radius: 999px;
  background: #F6F6F6;
  box-shadow: inset -1px 0px 2px rgba(169, 169, 169, 0.25);
}
div.bwf-2 > .separator.v2::after {
  content: "";
  width: 20px;
  height: 20px;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 999px;
  background: #F6F6F6;
  box-shadow: inset 1px 0px 2px rgba(169, 169, 169, 0.25);
}
div.bwf-2 > .message {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  color: #444444;
  display: flex;
  flex-direction: column;
  gap: 15px;
  user-select: none;
}
div.bwf-2 > .message ion-item ion-avatar {
  width: 46px;
  height: 46px;
}
div.bwf-2 > .message .bubble {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  background: #FFF6DE;
  border-radius: 0 10px 10px 10px;
}
div.bwf-2 > .message .bubble > p {
  margin: 0;
}
div.bwf-2 > .message .bubble.danger {
  background: #FFDCD8;
  color: #F73E32;
}
div.bwf-2 > .message .bubble.feedback .stars {
  display: flex;
  align-content: space-between;
  justify-content: space-between;
}
div.bwf-2 > .message .bubble.feedback .edit {
  font-size: 17px;
}
div.bwf-2 > .message .bubble:has(.stars):not(.editable) {
  pointer-events: none;
}
div.bwf-2 > .message .bubble:has(.stars):not(.editable) .stars .edit {
  display: none;
}
div.bwf-2 > .message .bubble.editable {
  pointer-events: auto;
  cursor: pointer;
}
div.bwf-2 > .actions-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 15px;
}
div.bwf-2 > .info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 15px;
}
div.bwf-2 > .services {
  margin-top: 5px;
}

.services {
  display: flex;
  flex-direction: column;
  justify-items: start;
  align-items: stretch;
  gap: 15px;
}
.services > .line {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.services > .line > div.data {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.services > .line > div.data > span:first-child {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  font-size: 13px;
  font-weight: 700;
  color: #444444;
}
.services > .line > div.data > span:first-child:after {
  content: "......................................................................................................................................................";
  flex-grow: 1;
  width: 10px;
  margin-left: 4px;
  margin-right: 4px;
  white-space: nowrap;
  overflow: hidden;
}
.services > .line > div.data > span:last-child {
  flex-shrink: 0;
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 14px;
  font-weight: 600;
  color: #444444;
}
.services > .line > div.data.primary > span {
  color: #DA4B90 !important;
  font-weight: 500 !important;
  font-size: 13px !important;
}
.services > .line > div.subtotal {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 14px;
  font-weight: 700;
  color: #1B1B1B;
}
.services .total {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.services .total > span:first-child {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  font-size: 13px;
  font-weight: 500;
  color: #1B1B1B;
}
.services .total > span:first-child:after {
  display: none;
}
.services .total > span:last-child {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 20px;
  font-weight: 700;
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin: -8px !important;
}
.action-buttons ion-button {
  height: auto;
  --box-shadow: 0;
  --padding-start: 0;
  --padding-end: 0;
}
.action-buttons ion-button ion-label {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 8px 12px;
}
.action-buttons ion-button ion-label ion-icon {
  font-size: 24px;
  --ionicon-stroke-width: 40px;
}
.action-buttons ion-button ion-label ion-icon.info {
  color: #1B9BE2;
}
.action-buttons ion-button ion-label ion-icon.history {
  color: #E89E0E;
}
.action-buttons ion-button ion-label ion-icon.add-green {
  color: #1ABE79;
}
.action-buttons ion-button ion-label ion-icon.repeat {
  color: #1ABE79;
}
.action-buttons ion-button ion-label ion-icon.move {
  color: #E89E0E;
}
.action-buttons ion-button ion-label ion-icon.cancel {
  color: #F73E32;
}
.action-buttons ion-button ion-label label {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  color: #444444;
  text-align: center;
  cursor: pointer;
}

.actions-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
}
.actions-row div.action {
  flex-grow: 1;
  flex-basis: 1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  border-radius: 10px;
  margin: -5px;
  padding: 5px;
  cursor: pointer;
  user-select: none;
  max-width: 120px;
}
.actions-row div.action:hover {
  background: #ECECEC;
}
.actions-row div.action ion-icon {
  font-size: 24px;
}
.actions-row div.action ion-icon.info {
  color: #1B9BE2;
}
.actions-row div.action ion-icon.history {
  color: #E89E0E;
}
.actions-row div.action ion-icon.add-green {
  color: #1ABE79;
}
.actions-row div.action ion-icon.repeat {
  color: #1ABE79;
}
.actions-row div.action ion-icon.move {
  color: #E89E0E;
}
.actions-row div.action ion-icon.cancel {
  color: #F73E32;
}
.actions-row div.action label {
  font-family: Montserrat;
  font-weight: 600;
  color: #1B1B1B;
  font-size: 14px;
  color: #444444;
  text-align: center;
  cursor: pointer;
}

form ion-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: inherit !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  --background: none;
}
form ion-list ion-item {
  --padding-start: 0;
  --inner-padding-end: 0;
  --highlight-background: var(--ion-border-color);
  --show-full-highlight: 0;
  box-shadow: none;
  --background: transparent;
  --min-height: 44px;
  --background-hover: #FFFFFF;
  --ripple-color: transparent;
  --background-activated-opacity: 0;
}
form ion-list ion-item ion-label[position=stacked] {
  color: var(--color) !important;
  display: contents !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px !important;
}
form ion-list ion-item.lib-invalid.ion-touched ion-input, form ion-list ion-item.lib-invalid.ion-touched ion-textarea, form ion-list ion-item.lib-invalid.ion-touched ion-select {
  border-color: #F73E32;
}
form ion-list ion-item ion-input, form ion-list ion-item ion-textarea, form ion-list ion-item ion-select {
  background: #FAFAFA !important;
  border: solid 1px #EFEFEF;
  border-radius: 10px;
  margin-top: 5px;
  --padding-start: 10px !important;
  --padding-end: 10px !important;
  caret-color: #000000;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px !important;
  width: 100%;
}
form ion-list ion-item ion-input::part(icon), form ion-list ion-item ion-textarea::part(icon), form ion-list ion-item ion-select::part(icon) {
  transform: translate3d(0px, 0px, 0px);
  color: black;
}
form ion-list ion-item ion-input.ion-invalid.ion-touched, form ion-list ion-item ion-textarea.ion-invalid.ion-touched, form ion-list ion-item ion-select.ion-invalid.ion-touched {
  border-color: #F73E32;
}
form ion-list ion-item ion-input, form ion-list ion-item ion-select {
  max-height: 35px;
  min-height: 35px;
}
form ion-list ion-item ion-textarea {
  min-height: 80px;
}
form ion-list ion-item .date-picker {
  display: flex;
  justify-content: space-between;
  padding-left: 10px !important;
}
form ion-list ion-item ion-buttons[slot=end] {
  margin-inline: 0;
}
form ion-list ion-item ion-buttons[slot=end].bottom {
  margin: auto 0 0 5px;
}
form ion-list ion-item ion-buttons[slot=end] ion-button {
  --padding-top: 0 !important;
  --padding-bottom: 0 !important;
  --padding-end: 0 !important;
  --padding-start: 0 !important;
  width: 36px !important;
  height: 36px !important;
}
form ion-list ion-item ion-buttons[slot=end] ion-button ion-icon[slot=icon-only] {
  color: #444444;
  font-size: 18px !important;
}
form ion-list ion-item ion-buttons[slot=end] button[type=button] {
  width: 33px !important;
  height: 33px !important;
}
form ion-list ion-item:has(ion-toggle) ion-buttons[slot=end] {
  margin-top: 0;
}
form ion-list ion-item ion-row {
  width: 100%;
  align-items: end;
}
form ion-list ion-item ion-row ion-col {
  padding: 0;
}
form ion-list ion-item ion-row ion-col ion-select {
  max-width: 100%;
}

.lib-invalid {
  border-color: #F73E32;
}

form.ng-submitted ion-input.ion-invalid, form.ng-submitted ion-input.lib-invalid, form.ng-submitted ion-textarea.ion-invalid, form.ng-submitted ion-textarea.lib-invalid {
  border: 1px solid #F73E32 !important;
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}

@media (min-width: 769px) {
  .hide-desktop {
    display: none;
  }
}

@media (max-width: 768px) {
  ion-footer {
    background: #F6F6F6;
  }
}

div[id=map] {
  border-radius: 10px !important;
}

.menu-accordion div.item {
  display: flex;
  gap: 15px;
  font-family: Montserrat, serif;
  font-weight: 600;
  font-size: 14px;
  color: #848484;
}
.menu-accordion div.item span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 133px;
}
.menu-accordion div.item.active {
  color: #DA4B90;
}
.menu-accordion div[slot=content] {
  background: #F6F6F6;
}
.menu-accordion div[slot=content] div.item {
  padding: 8px 17px;
  cursor: pointer;
  overflow: hidden;
}
.menu-accordion div[slot=content] div.item:hover {
  background: #ECECEC;
}
.menu-accordion div[slot=content] ion-icon {
  font-size: 20px;
}
.menu-accordion ion-item[slot=header] {
  --background: #F6F6F6;
}
.menu-accordion ion-item[slot=header] .ion-accordion-toggle-icon {
  margin-left: 7px;
  font-size: 20px;
}

.bogof {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.bogof .item {
  width: 65px;
  height: 65px;
  line-height: 65px;
  border: 2px solid #DA4B90;
  border-radius: 50%;
  text-align: center;
  color: #DA4B90;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
}
.bogof .transparent {
  opacity: 0.5;
  font-weight: 500;
  border-width: 1px;
}
.bogof .price {
  background: linear-gradient(140.65deg, rgba(218, 75, 144, 0.69) 4.51%, #DA4B90 88.04%);
  color: #FFFFFF;
  border-width: 0;
}

ion-list-header > h3 {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}
ion-list-header > h3 > a {
  font-family: PTRootUIWeb;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: #444444;
  color: #848484;
  font-size: 14px;
  text-decoration: underline;
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #3880ff);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

ion-toggle {
  padding: 12px;
}

[hidden] {
  visibility: hidden;
}