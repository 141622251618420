@import "media-breakpoints";

.bwf {
  overflow: hidden;

  & > * {
    margin: 16px 16px;
  }

  & > h2 {
    margin-top: 24px;

    & + .data {
      margin-top: 0;
    }
  }

  .message {
    display: block;

    & > ion-item {
      --padding-start: 0;
      --padding-end: 0;
      --ion-safe-area-left: 0;
      --ion-safe-area-right: 0;
      --inner-padding-start: 0;
      --inner-padding-end: 0;
    }

    & > .author {
      display: grid;
      grid-template-rows: minmax(max-content, 0) minmax(max-content, 0);
      grid-template-columns: max-content auto max-content;
      margin-bottom: 12px;

      lib-avatar {
        grid-row: 1 / span 2;
        grid-column: 1;
        align-self: center;
        width: 40px;
        height: 40px;
        margin-right: 16px;

      }

      h5 {
        grid-row: 1;
        grid-column: 2;
        align-self: end;
        margin: 0;
      }

      ion-note {
        grid-row: 2;
        grid-column: 2;
        align-self: start;
        margin: 0;
      }

      ion-buttons {
        grid-row: 1 / span 2;
        grid-column: 3;
      }

    }

    & > .buttons {
      margin: 0;

      a {
        text-decoration: underline;
      }
    }

    & > .content {
      display: block;
      overflow: hidden;
      padding: 0 16px;
      margin-bottom: 8px;
      border-radius: 0 16px 16px 16px;

      p {
        lib-stars {
          position: relative;
          top: 2px;
        }
      }

      app-images-set {
        margin: 16px 0;
      }

      &.primary {
        //background-color: #E5ECF1;
        background-color: rgba(var(--ion-color-primary-rgb), 0.08);
        color: var(--ion-text-color);
      }

      &.success {
        background-color: rgba(var(--ion-color-success-rgb), 0.08);
        color: var(--ion-color-success);
      }

      &.canceled {
        background-color: rgba(var(--ion-color-danger-rgb), 0.08);
        color: var(--ion-color-danger);
      }
    }

    &:last-child {
      margin-bottom: 24px;
    }
  }

  .divider {
    border-top: 2px;
    border-top-style: dashed;
    border-top-color: var(--ion-border-color);
    margin: 0;
  }

  & > .data {
    font-size: 15px;

    & > .data-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin: 12px 0;

      ion-icon {
        margin-right: 8px;
        flex-shrink: 0;
      }

      ion-text {
      }
    }
  }

  & > .services {
    font-size: 15px;
    margin: 24px 16px;

    & > .row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      width: 100%;

      & > span:first-child {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &:after {
          content: "......................................................................................................................................................";
          flex-grow: 1;
          width: 10px;
          margin-left: 4px;
          margin-right: 4px;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      & > span:last-child {
        flex-shrink: 0;
      }
    }
  }

  & > .notifications {
    ion-chip {
      margin-inline: 0px;
      margin-right: 8px;
    }
  }

  & > .buttons {
    margin: 16px;
  }
}
