@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap');
@import "PT-Root-UI/PT-Root-UI_All";

@import "new-design-mixins";

html {
  @include font-main;

  --padding-start: 15px;
  --inner-padding-end: 15px;

  ol {
    padding-left: 20px;
  }
}

ion-app {
  background: $color-bg;

  & > div.circles {

    display: none;

    @include for-mobile {
      display: none;
    }

    div:nth-of-type(1) {
      width: 300px;
      height: 300px;
      background: $color-primary-2;
      position: absolute;
      top: -87px;
      left: -59px;
      border-radius: 999px;
    }

    div:nth-of-type(2) {
      width: 50px;
      height: 50px;
      background: $color-primary-2;
      position: absolute;
      top: 232px;
      left: 54px;
      border-radius: 999px;
    }

    div:nth-of-type(3) {
      width: 157px;
      height: 157px;
      background: $color-primary-2;
      position: absolute;
      top: 408px;
      left: -78px;
      border-radius: 999px;
    }

    div:nth-of-type(4) {
      width: 157px;
      height: 157px;
      background: $color-primary-2;
      position: absolute;
      top: 725px;
      left: -78px;
      border-radius: 999px;
    }

    div:nth-of-type(5) {
      width: 100px;
      height: 100px;
      background: $color-primary-2;
      position: absolute;
      top: 890px;
      left: 29px;
      border-radius: 999px;
    }

    div:nth-of-type(6) {
      width: 300px;
      height: 300px;
      background: $color-primary-2;
      position: absolute;
      top: -140px;
      right: 52px;
      border-radius: 999px;
    }

    div:nth-of-type(7) {
      width: 90px;
      height: 90px;
      background: $color-primary-2;
      position: absolute;
      top: 149px;
      right: 40px;
      border-radius: 999px;
    }

    div:nth-of-type(8) {
      width: 230px;
      height: 230px;
      background: $color-primary-2;
      position: absolute;
      top: 675px;
      right: -110px;
      border-radius: 999px;
    }
  }
}

ion-header {

  @include for-desktop {
    ion-buttons:has(ion-menu-button) {
      display: none !important;
    }
  }

  ion-title {
    padding-inline-start: $safe-area;
    padding-inline-end: $safe-area;

    ion-searchbar {
      @include for-mobile {
        &.sc-ion-searchbar-ios-h {

          --border-radius: 0;
          border-radius: 5px;

          height: 30px;
          --ion-item-background: #F2F2F2;
        }
      }
    }

    .toolbar-background {
      --border-width: 0;
    }
  }

}

ion-menu {

  ion-menu-toggle {
    & > ion-item {
      font-family: $font-header;
      font-weight: 500;
      color: $color-text-2;

      ion-icon {
        font-size: 20px;
      }

      font-size: 14px;
    }
  }
}


ion-content {
  --ion-background-color: #{$color-bg};;
  --ion-font-family: $font-main;
}

div.side-menu {

  position: absolute;
  top: 0px;
  right: 50%;
  transform: translateX(-260px);

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  @include font-header;
  font-size: 14px;
  color: $color-text-3;

  cursor: pointer;
  user-select: none;

  padding: 0 20px;
  --color: red;

  & > div.logo {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    cursor: pointer;
    user-select: none;

    height: 53px;

    margin-bottom: 23px;

    & > img {
      height: 40px;
    }

    & > .slogan {
      @include font-main;
      font-size: 12px;
      color: $color-text-3;
      width: 120px;
    }
  }

  & > div.item {
    cursor: pointer;
    user-select: none;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;

    padding: 8px 17px;
    border-radius: 5px;

    ion-icon {
      font-size: 20px;
    }

    & > span {
      @include ellipsis;
      max-width: 133px;
    }

    //&:before {
    //  content: '';
    //  width: 2px;
    //  height: 20px;
    //}

    @include hoverable;

    &.active {
      color: $color-primary;

      &:before {
        background: $color-primary;
      }
    }

    &.inactive {
      cursor: auto;

      &:hover {
        background: initial;
      }
    }
  }

  & > div.separator {
    height: 1px;
    background: #e3e3e3;
    margin: 10px 0;
    margin-left: 50px;
    margin-right: 35px;

  }

  @include for-mobile {
    display: none;
  }
}

div.content-section {
  border-left: none;
  border-right: none;

  &:not(:first-child) {
    .card.transparent:first-child {
      padding-top: 0;
    }

    @include for-mobile {
      .card.transparent-mobile:first-child {
        padding-top: 0;
      }
    }
  }

  @include for-desktop {
    &:first-child, lib-refresher + & {
      &:has(.card:first-child:not(.transparent)) {
        margin-top: $safe-area;
      }
    }
  }

  @include for-mobile {
    margin-top: 0;
    margin-bottom: 0;

    * > form > .cards-list:first-child > .card:first-child,
    &:first-of-type > form > .cards-list:first-child > .card:first-child,
    &:first-of-type > .cards-list:first-child > .card:first-child,
    &:first-of-type > .card:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  @include for-desktop {
    border-bottom: none !important; // Временно, чтобы перебить основной контент секшен
  }

  &.sticky {
    padding: 0 !important;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 3; // Чтобы перекрывало чекбоксы. Почему-то они поверх идут при скроле.
  }

}

h1 {
  @include font-header;
  font-size: 24px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 8px;

}

h2 {
  @include font-header;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 8px;
}

h3 {
  @include font-header;
  font-size: 17px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 8px;
}

h4 {
  @include font-header;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 8px;
}

.online::after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: $color-green;
  border-radius: 50%;
}

.offline::after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  background: $color-red;
  border-radius: 50%;
}

ion-header.header-md {
  @include box-shadow;

  &::after {
    display: none;
  }
}

ion-title {
  @extend h1;
  color: unset;
  margin: 0;
}

ion-button {
  font-size: 16px;
  --border-radius: #{$border-radius};
  --box-shadow: 0px 1px 3px rgba(169, 169, 169, 0.25);
  text-transform: none;
  letter-spacing: normal;
  font-weight: 700;
  margin: 0;

  &.button-outline {
    margin: 0;
    --border-width: 1px;
    --border-color: #d6d6d6;
    --background-hover: #d6d6d6;
    --background-hover-opacity: 1;
    --box-shadow: 0;
    color: $color-text-1;
  }

  &.button-small {
    font-size: 13px;
  }

  &.button-has-icon-only {
    --box-shadow: none;
  }

  &[shape=circle] {
    --border-radius: 999px !important;
    --border-color: #{$color-line};
    --border-style: solid;
    --border-width: 1px;
    width: 56px;
    height: 56px;
    color: $color-text-1;
  }
}

//ion-toggle {
//  &.md {
//    padding: 5px;
//    margin: -5px;
//  }
//}

ion-icon {
  --ionicon-stroke-width: 40px;
}

ion-accordion-group.accordion-group-expand-inset {

  ion-accordion {
    box-shadow: none;
    color: $color-text-2;
    border: solid 1px $color-line;

    &:not(.accordion-expanding):not(.accordion-expanded) {
      margin-top: -1px;
    }

    &:first-of-type {
      margin-top: 0px;
    }

    &:last-of-type {
      margin-bottom: 0px;
    }

    &.accordion-expanded {
      ion-item {
        --background: #{$color-hover};
      }
    }

    ion-item[slot=header] {
      --border-style: none;

      font-size: 15px;
      font-weight: 500;

      color: $color-text-2;

      & > div.content {
        display: block;
        @include ellipsis-2;
        margin: 10px 0;
      }

      &:has(ion-buttons) {
        --inner-padding-end: 5px;
      }

      ion-icon {
        font-size: 15px;
        color: $color-text-3;
      }
    }
  }
}

ion-list:not(.classic) {

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 7px;

  &.list-md {
    background: none;
  }

  &.list-ios {
    background: none;
  }

  padding: 0;
  padding-top: 2px;

  contain: initial;

  &.colored-primary {
    --ion-item-background: #{$color-primary-3};

    ion-item {
      border: none;
      box-shadow: none;
      --background: #{$color-primary-3} !important;
    }
  }

  &.colored-secondary {
    --ion-item-background: #{$color-secondary-3} !important;

    ion-item {
      border: none;
      box-shadow: none;
      --background: #{$color-secondary-3} !important;
    }
  }

  &.transparent {
    gap: 20px;

    ion-item {
      --background: none;
      margin: -10px -10px;
      --padding-start: 10px;

      ion-avatar:has(ion-icon) {
        background: $color-line;
      }
    }
  }

  ion-reorder-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 7px;
  }

  ion-virtual-scroll .item-container:not(:first-child) {
    padding-top: 7px;
  }

  ion-item {
    $icon-size: 17px;

    //border: $border;
    --border-radius: #{$border-radius};
    //@include box-shadow;
    //border: $border;
    --background: #f7f7f7;

    //@include box-shadow;
    //margin: 2px;

    --border-style: none;
    --min-height: none;
    --padding-start: 15px;
    --padding-top: 10px;
    --padding-bottom: 10px;

    --detail-icon-color: #{$color-text-3};
    --detail-icon-font-size: #{$icon-size};
    --detail-icon-opacity: 1;

    color: $color-text-1;

    font-size: 15px;
    font-weight: 500;

    @mixin accent-up {
      font-family: $font-header;
      font-weight: 700;
      font-size: 14px;
      color: $color-text-1;
    }

    @mixin accent-down {
      font-family: $font-main;
      font-weight: 400;
      font-size: 12px;
      color: $color-text-3;
    }

    &:has(ion-buttons) {
      --inner-padding-end: 5px;

      ion-buttons {
        margin: 0;

        ion-button {
          --padding-start: 0 !important;
          --padding-end: 0 !important;
          --padding-top: 0 !important;
          --padding-bottom: 0 !important;
          width: 35px !important;
          height: 35px !important;
          margin: 0;

          ion-icon[slot=icon-only] {
            color: $color-text-2;
            font-size: $icon-size !important;
          }
        }
      }
    }

    & > ion-avatar, & > lib-avatar {
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 15px;
      width: 40px;
      height: 40px;

      &:has(ion-icon) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        ion-icon {
          font-size: $icon-size;
        }
      }
    }

    & > ion-label, & > div.content {
      display: block;
      @include ellipsis-2;
      margin: 0;

      span.accent-up {
        @include accent-up();
        line-height: 18px;
      }

      span.accent-down {
        @include accent-down();
      }

      & > * {
        @include ellipsis;
        line-height: 17px;
      }

      & > h4 {
        font-family: $font-header;
        font-weight: 600;
        font-size: 15px;
        margin: 0;

        & > * {
          @include ellipsis;
        }
      }

      & > div {
        & > * {
          @include ellipsis;
        }
      }

      & > p {
        margin: 0 !important;
        font-size: 13px;
        font-family: $font-main;
        color: $color-text-2;
      }
    }

    & > ion-icon[slot=end] {
      color: $color-text-3;
      font-size: $icon-size !important;
    }

    & > *[slot=end] {
      margin-left: 10px;
    }

    &.disabled {
      ion-label, img {
        opacity: 0.5;
      }
    }

    &.colored-primary {
      --background: #{$color-primary-3};
      border: none;
      box-shadow: none;
    }

    &.colored-secondary {
      --background: #{$color-secondary-3};
      border: none;
      box-shadow: none;
    }

    &.bwf-2 {
      .date {
        font-family: $font-header;
        font-weight: 700;
        font-size: 14px;
        color: $color-primary;
      }

      .day {
        &:before {
          content: '|';
          position: relative;
          bottom: 1px;
          margin: 0 5px;
          font-size: 12px;
        }
      }

      ion-button.today, ion-button.tomorrow, ion-button.online, {
        &.online {
          --background: #{$color-blue};
        }

        &.today, &.tomorrow {
          --background: #{$color-green};
        }
      }
    }

    &.pli {
      &.colored-primary {
        background: $color-secondary-3;
      }

      & > ion-label {
        ion-icon[name=checkmark] {
          color: $color-green;
          position: relative;
          top: 0.15em;
          --ionicon-stroke-width: 80px;
        }
      }

      span.price {
        @include accent-up;
        line-height: 17px;

        &.new {
        }

        &.old {
          color: $color-text-3;
          font-size: 11px;
          text-decoration: line-through;
        }
      }

      span.time {
        font-size: 13px;
        color: $color-text-2;

        span.price + & {
          &:before {
            content: '|';
            position: relative;
            bottom: 1px;
            margin: 0 5px;
            color: $color-text-3;
            font-size: 12px;
          }
        }
      }

      span.price + span.time {
        &:before {
          content: '|';
          position: relative;
          bottom: 1px;
          margin: 0 5px;
          color: $color-text-3;
          font-size: 12px;
        }
      }

      span.icons:has(*) {
        &:before {
          content: '|';
          position: relative;
          bottom: 1px;
          margin: 0 5px;
          color: $color-text-3;
          font-size: 12px;
        }

        & > span {
          text-transform: uppercase;
          font-size: 13px;
          font-family: $font-header;
          font-weight: 900;
        }

        & > ion-icon, & > span {
          margin: 0 4px;
          position: relative;
        }
      }
    }

    &.promo {
      p.info, div.info {
        span.discount {
          @include font-header;
          font-size: 14px;
          font-weight: 700;
          color: $color-primary;
        }

        span.dates {
          position: relative;
          bottom: 1px;
          font-size: 13px;
          color: $color-text-2;
        }

        span.discount + span.dates {
          &:before {
            content: '|';
            position: relative;
            bottom: 1px;
            margin: 0 5px;
            color: $color-text-3;
          }
        }
      }
    }

    &.client {
      ion-icon[name=person-add-outline] {
        color: $color-green;
      }
    }

    &.master-details {

      ion-label {
        //margin: 4px 0;
      }

      p.days {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        height: 17px;
        overflow: hidden;
        flex-wrap: wrap;

        div.day {
          white-space: nowrap;
        }

        span.day:not(:first-of-type):before {
          content: '|';
          color: $color-text-3;
          margin: 0 10px;
          position: relative;
          bottom: 1px;
        }
      }
    }

    &.master-booking {
      .date { // как у bwf-2
        @include accent-up();
        color: $color-primary;
      }

      .item {
        font-size: 13px;
        color: $color-text-2;
      }

      & > ion-icon {
        margin-left: 5px;
        color: $color-green;
      }

      .two-lines {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: initial;
        text-wrap: wrap;
      }
    }

    &.master-window {
      --background: #{$color-blue-2};

      .date { // как у bwf-2
        @include accent-up();
        color: $color-blue;
      }

      ion-avatar {
        background: $color-blue;

        ion-icon {
          color: $color-white;
        }
      }
    }

    &.master-pause {
      --background: #{$color-green-2};

      .date { // как у bwf-2
        @include accent-up();
        color: $color-green;
      }

      ion-avatar {
        background: $color-green;

        ion-icon {
          color: $color-white;
        }
      }
    }
  }

  ion-list-header {
    padding-left: 0;
    padding-right: 0;

    &:first-child {
      //min-height: initial;
      //
      //h1, h2, h3 {
      //  margin-top: 0;
      //}
    }
  }

  div.image-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: $safe-area;
    padding-bottom: 13px;

    .image {
      width: 80px;
      height: 80px;
      cursor: pointer;
    }

    .button {

    }
  }
}


p {
  line-height: initial;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}

.chips {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;

  .chips + & {
    margin-top: 10px;
  }
}

.chip {
  padding: 7px 15px;
  margin: 0;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  border-radius: $border-radius;
  background: $color-white;

  & > div.label {
    @include font-main;
    font-size: 11px;
    color: $color-text-3;
  }

  &.gray {
    background: #f7f7f7;

    & > div.label {
      @include font-main;
      font-size: 11px;
      color: #666666;
    }
  }

  &:not(.gray) {
    @include for-desktop {
      @include box-shadow;
    }

    @include for-mobile {
      @include box-shadow;
    }
  }

  & > div.value {
    @include font-header;
    font-size: 14px;
    color: $color-primary;
    margin-top: -2px;
  }


  & > ion-buttons {
    grid-row: 1/3;
    justify-self: center;
    align-self: center;
    font-size: 15px;
    margin-left: 15px;

    .button-has-icon-only.button-clear {
      --padding-top: 2px;
      --padding-end: 2px;
      --padding-bottom: 2px;
      --padding-start: 2px;
    }

    & > ion-button {
      width: 20px !important;
      height: 20px !important;
      min-height: 20px !important;

      ion-icon {
        color: $color-text-3;
      }
    }
  }

  &.empty {
    padding: 13px 15px !important;

    & > div.label {
      font-size: 14px;
      color: $color-text-2;
    }

    & > div.value {
      display: none;
    }

    & > ion-buttons {
      display: none;
    }
  }
}

.data-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  div.image-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: $safe-area;
    padding-bottom: 13px;

    .image {
      width: 80px;
      height: 80px;
      cursor: pointer;
    }

    .button {

    }
  }

  div.item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: solid 1px $color-line;

    cursor: pointer;

    div.label {
      @include font-header;
      flex-shrink: 0;
      margin-right: $safe-area;
      font-size: 14px;
      font-weight: 500;

      div.sub-label {
        color: $color-text-3;
        font-size: 12px;
      }
    }

    div.value {
      @include font-header;
      @include ellipsis;
      text-align: right;
      flex-grow: 1;
      font-size: 15px;
      font-weight: 600;

      &.gray {
        color: $color-text-3;
        font-weight: 500;
      }

      &.big {
        white-space: inherit;
      }

      &.active {
        color: $color-primary;
        font-weight: 500;
      }
    }
  }
}

.list {
  .items, ion-reorder-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: $cards-space;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  & > .one {
    grid-column: span 6;
  }

  & > .two {
    grid-column: span 3;
  }

  & > .three {
    grid-column: span 2;
  }
}

.cards-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  gap: 10px;
  padding: 0;
  margin-bottom: 10px;

  &.v2 {
    gap: $safe-area;
    margin: $safe-area 0;

    .card {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}


.card {
  display: block;
  background: $color-white;
  border-radius: $border-radius;
  @include box-shadow;
  overflow: hidden; //Если хидден, то тени на фильтрах немного режутся
  padding-top: $safe-area;
  padding-bottom: $safe-area;

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & > h2 {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;

    & > a {
      @include font-main;
      color: $color-text-3;
      font-size: 14px;
      text-decoration: underline;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  &.padding {
    padding-left: $safe-area;
    padding-right: $safe-area;
  }

  &.no-padding {
    padding: 0;
  }

  &.padding-mobile {
    @include for-mobile {
      padding-left: $safe-area;
      padding-right: $safe-area;
    }
  }

  &.no-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.transparent {
    cursor: initial;
    background: none;
    box-shadow: none;
    border-radius: 0;
    border: none;
    overflow: visible;

    .transparent + & {
      padding-top: 0;
    }

    @include for-mobile {
      .transparent + &, .transparent-mobile + & {
        padding-top: 0;
      }
    }


    & > h1, & > h2, & > h3 {
      padding-top: 4px;
    }
  }

  &.transparent-mobile {
    @include for-mobile {
      cursor: initial;
      background: none;
      box-shadow: none;
      border-radius: 0;
      border: none;
      overflow: visible;

      .transparent + &, .transparent-mobile + & {
        padding-top: 0;
      }

      & > h1, & > h2, & > h3 {
        padding-top: 4px;
      }
    }
  }

  &.sticky {
    padding-top: $safe-area;
    padding-bottom: $safe-area;
    background: $color-bg;
    margin-left: -3px;
    margin-right: -3px;
  }

  &.bottom-fixed-mobile {
    @include for-mobile {
      position: fixed;
      width: 100%;
      bottom: 0;
    }
  }

  ion-avatar.connection-avatar {
    background-color: #C06C84;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
      width: 25px;
      height: 25px;
    }
  }

  &.master {
    $space: 16px;
    padding: $safe-area;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    gap: $space;

    cursor: pointer;
    user-select: none;

    div.head {
      @include figure-1;
      gap: 15px;

      & > ion-avatar {
        width: 70px;
        height: 70px;
      }

      & > div {

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        h3 {
          margin: 0;
          font-size: 17px;
          font-weight: 700;
          color: $color-text-1;
        }

        p.specs {
          margin: 0;
          margin-top: 3px;
          @include font-main;
          @include ellipsis;
          color: $color-text-3;
          font-size: 14px;
        }

        p.digits {
          margin: 0;
          margin-top: 3px;

          @include font-main;

          span.digit {
            font-size: 15px;

            ion-icon {
              bottom: 1.5px;

              &[name=star] {
                bottom: 1.5px;
              }
            }
          }
        }
      }

    }

    div.address {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      position: relative;
      left: -3px;

      & > ion-icon {
        flex-shrink: 0;
        font-size: 20px;
        color: $color-text-3;
        margin-right: 8px;
      }

      & > div {
        overflow: hidden;
      }

      div.line1 {
        @include font-header;
        @include ellipsis;
        font-size: 14px;
        color: $color-text-2;
      }

      div.line2 {
        @include font-main;
        @include ellipsis;
        font-size: 13px;
        color: $color-text-3;
      }
    }

    div.text {
      @include font-main;
      @include ellipsis-4;
      font-size: 14px;
      color: $color-text-2;
    }

    div.price-list-item {
      background: $color-secondary-3;
    }
  }
}

.masters-list {
  .card-container {
    padding-top: $cards-space;
  }
}


.price-list-item {
  @extend .card;
  padding: 10px 15px;
  overflow: hidden;
  position: relative;

  .first {
    @include font-main;
    color: $color-text-1;
    font-size: 15px;
    font-weight: 500;
    @include ellipsis;
  }

  .second {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;

    margin-top: 2px;

    .price {
      @include font-header;
      font-size: 12px;
      font-weight: 600;
    }

    .time {
      @include font-main;
      font-size: 12px;
      color: $color-text-3;

      &:before {
        content: "|";
        margin: 0 10px;
        position: relative;
        bottom: 1px;
      }
    }
  }
}

div.promo {
  @extend .card;
  padding: 10px 16px;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  h3 {
    @include font-main;
    color: $color-text-1;
    font-size: 15px;
    margin: 0;
    padding: 0;
  }

  h4 {
    @include font-main;
    color: $color-text-3;
    font-size: 13px;
    margin: 0;
    padding: 0;
    margin-top: 2px;
  }

  div.discount {
    @include font-header;
    color: $color-primary;
    font-size: 17px;

    & {
      position: absolute;
      bottom: 17px;
      right: 14px;
      margin: 0;
    }
  }
}

.windows {
  @extend .promo;

  & > div {
    @include font-main;
    font-size: 12px;
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: 15px;
    overflow: hidden;
    flex-wrap: wrap;

    .window {
      white-space: nowrap;
    }

    .window:not(:first-of-type):before {
      content: '|';
      color: $color-text-3;
      margin: 0 10px;
      position: relative;
      bottom: 1px;
    }

  }
}

.question {
  @extend .card;
  @include ellipsis;
  border: solid 1px $color-line;
  padding: 10px 15px;
}

div.digits {
  position: absolute;
  right: 0;
  bottom: -1px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.95);
  background: $color-white;
  padding: 8px 16px;
  border-radius: $border-radius 0 0 0;
  overflow: hidden;
  border-top: solid 1px $color-line;
  border-left: solid 1px $color-line;
  cursor: pointer;
  user-select: none;
}

span.digit {
  font-weight: 600;

  @include font-header;
  font-size: 13px;

  &:not(:last-of-type) {
    margin-right: 12px;
  }

  span.top {
    font-size: 9px;
    position: relative;
    top: -4px;
    color: var(--ion-color-step-400);
  }

  span.bottom {
    font-size: 9px;
    position: relative;
    bottom: 0px;
    color: var(--ion-color-step-400);
    display: none;
  }

  ion-icon {
    vertical-align: text-bottom;
    position: relative;
    bottom: 1px;

    &[name=star] {
      bottom: 1px;
    }
  }

  ion-text {
    position: relative;
  }

  &.star {
    color: $color-yellow;
  }

  &.returnability {
    color: $color-green;
  }

  &.reliability {
    color: $color-blue;
  }

}

ion-searchbar {
  height: 36px;
  --ion-item-background: #F2F2F2;

  &.sc-ion-searchbar-ios-h {
    padding: 0;
    height: 36px;
  }

  input.searchbar-input.sc-ion-searchbar-ios {
    @include font-main;
    font-size: 15px;
    padding-left: 40px !important;
  }

  ion-icon.searchbar-search-icon.sc-ion-searchbar-ios {
    width: 17px;
    color: #888;
    margin: 0 8px !important;
  }
}

div.banner {
  padding: 15px;
  background: $gradient-primary;
  border-radius: $border-radius;
  position: relative;

  @include box-shadow;

  h2 {
    @include font-header;
    color: $color-white;
    margin: 0;
    padding: 0;
    font-size: 17px;
  }

  h3 {
    @include font-header;
    color: $color-white;
    margin: 0;
    padding: 0;
  }

  p {
    @include font-main;
    //color: $color-primary-1;
    font-weight: 400;
    color: $color-white;
    margin: 0;
    padding: 0;
    margin-top: 8px;
  }

  ion-button {
    min-height: auto;
  }

  &.b1 {
    position: relative;
    padding-left: 80px;
    text-align: left;

    img {
      position: absolute;
      top: 10px;
      left: 0;
      width: 64px;
    }
  }

  &.b2 {
    position: relative;
    padding-left: 120px;
    padding-right: 50px;
    text-align: left;
    overflow: hidden;

    p {
      font-size: 13px;
    }

    img {
      position: absolute;
      top: 10px;
      left: -20px;
      max-height: unset;
      height: 200px;
      width: 120px;
    }

    ion-button {
      margin-top: 15px;
      --background: #ffffff;
    }
  }

  &.b3 {
    position: relative;
    padding-left: 120px;
    padding-right: 50px;
    text-align: left;
    overflow: hidden;

    p {
      font-size: 13px;
    }

    img {
      position: absolute;
      top: 10px;
      left: -5px;
      max-height: unset;
      width: 104px;
      height: 126px;
    }

    ion-button {
      margin-top: 15px;
      --background: #ffffff;
    }
  }

  &.b4 {
    position: relative;
    text-align: left;
    overflow: hidden;
    padding-right: 50px;

    p {
      font-size: 13px;
    }

    img {
      position: absolute;
      bottom: 10px;
      right: 15px;
      max-height: unset;
      width: 180px;
      height: 70px;
    }

    ion-button {
      margin-top: 15px;
      --background: #ffffff;
      font-size: 15px;
    }
  }
}


.figure-1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  & > .avatar {
    flex-shrink: 0;
  }

  & > .text {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    @include ellipsis;

    * {
      @include ellipsis;
      margin: 0;
      padding: 0;
    }
  }

  & > .details {

    flex-shrink: 0;

    display: flex;
    flex-direction: column;

    padding: 5px;
    border-radius: 999px;


    ion-icon {
      font-size: 14px;
      color: $color-text-3;
    }

    //&:before {
    //  content: '>';
    //}
  }

  &.clickable-circle {
    margin: -3px;
    padding: 3px;
    border-radius: 999px;

    //margin: -10px -20px;
    //padding: 10px 20px;
    //border-radius: 0;

    margin: -10px;
    padding: 10px;
    border-radius: $border-radius;

    @include hoverable;

  }

  &.clickable-square {
    //margin: -10px -20px;
    //padding: 10px 20px;
    //border-radius: 0;

    //margin: -3px;
    //padding: 3px;
    //border-radius: 999px;

    margin: -10px;
    padding: 10px;
    border-radius: $border-radius;

    @include hoverable;
  }

  &.address {

    & > .avatar {
      width: 36px;
      height: 36px;

      .icon-container {
        background: #F2F2F2;
        color: $color-text-2;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center
      }
    }

    h4 {
      grid-row: 1;
      grid-column: 2;
      margin: 0;
    }

    p {
      grid-row: 2;
      grid-column: 2;
      margin: 0;
      font-size: 13px;
    }
  }

}

.master-note {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.figure-2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  & > ion-toggle.toggle {
    flex-shrink: 0;
    padding: 0;
  }

  & > div.label {
    flex-grow: 1;

    @include font-header;
    font-size: 14px;
    color: $color-text-2;
  }

  & > div.options {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    user-select: none;

    & > div.current {
      @include font-main;
      font-size: 13px;
      color: $color-primary;
    }

    & > div.details {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      color: $color-text-3;
      font-size: 13px;
    }
  }
}

.date-big {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  @include font-header;
  font-size: 24px;
  color: $color-primary;
  font-weight: 700;

  .extra {
    font-size: 12px;
    color: $color-text-3;
    font-weight: 600;
    position: relative;
    bottom: 5px;
  }

  .status {
    font-size: 10px;
    font-weight: 600;
    color: $color-white;
    padding: 5px 10px;
    border-radius: 999px;


    &.today {
      background: $color-green;
    }

    &.online {
      background: $color-blue;
    }

    &.done {
      background: $color-green;
    }

    &.canceled {
      background: $color-red;
    }

    &.moved {
      background: $color-blue;
    }

    &.circle {
      text-align: center;
      width: 17px;
      height: 17px;
      line-height: 17px;
      padding: 0 !important;
    }

  }
}

div.bwf-2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: visible;

  & > .header {

    display: flex;
    flex-direction: column;

    .text {
      h4 {
        margin: 0;
      }

    }

  }

  & > .separator {
    border-bottom: $border;
    position: relative;

    &.v2 {
      margin-left: -$safe-area;
      margin-right: -$safe-area;
      border-style: dashed;
      border-width: 1px;
      border-color: $color-line;

      &::before {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        top: -10px;
        left: -10px;
        border-radius: 999px;
        background: $color-bg;
        box-shadow: inset -1px 0px 2px rgb(169 169 169 / 25%);
      }

      &::after {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        top: -10px;
        right: -10px;
        border-radius: 999px;
        background: $color-bg;
        box-shadow: inset 1px 0px 2px rgb(169 169 169 / 25%);
      }
    }
  }

  & > .message {
    @include font-main;
    color: $color-text-2;

    display: flex;
    flex-direction: column;
    gap: 15px;

    user-select: none;

    ion-item {
      ion-avatar {
        width: 46px;
        height: 46px;
      }
    }

    .bubble {
      display: flex;
      flex-direction: column;
      gap: 10px;

      padding: 15px;
      background: $color-secondary-3;
      border-radius: 0 $border-radius $border-radius $border-radius;

      & > p {
        margin: 0;
      }

      &.danger {
        background: #FFDCD8;
        color: $color-red;
      }

      &.feedback {
        .stars {
          display: flex;
          align-content: space-between;
          justify-content: space-between;
        }

        .edit {
          font-size: 17px;
        }
      }

      &:has(.stars):not(.editable) {
        pointer-events: none;

        .stars .edit {
          display: none;
        }
      }

      &.editable {
        pointer-events: auto;
        cursor: pointer;
      }
    }
  }

  & > .actions-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 15px;
  }

  & > .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 15px;
  }

  & > .services {
    margin-top: 5px;
  }
}

.services {

  display: flex;
  flex-direction: column;
  justify-items: start;
  align-items: stretch;
  gap: 15px;

  & > .line {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    width: 100%;

    & > div.data {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & > span:first-child {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        @include font-main;
        font-size: 13px;
        font-weight: 700;
        color: $color-text-2;

        &:after {
          content: "......................................................................................................................................................";
          flex-grow: 1;
          width: 10px;
          margin-left: 4px;
          margin-right: 4px;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      & > span:last-child {
        flex-shrink: 0;

        @include font-header;
        font-size: 14px;
        font-weight: 600;
        color: $color-text-2;
      }

      &.primary {
        & > span {
          color: $color-primary !important;
          font-weight: 500 !important;
          font-size: 13px !important;
        }
      }
    }

    & > div.subtotal {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      @include font-header;
      font-size: 14px;
      font-weight: 700;
      color: $color-text-1;
    }
  }

  .total {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    & > span:first-child {
      @include font-main;
      font-size: 13px;
      font-weight: 500;
      color: $color-text-1;

      &:after {
        display: none;
      }
    }

    & > span:last-child {
      @include font-header;
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  margin: -8px !important; // Компенсация за внутренний паддинг кнопок, чтобы видимый текст между кнопками и другими блоками по вертикали был равен сейф эрии 20px

  ion-button {
    height: auto;
    --box-shadow: 0;
    --padding-start: 0;
    --padding-end: 0;

    ion-label {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      padding: 8px 12px;

      ion-icon {
        font-size: 24px;
        --ionicon-stroke-width: 40px;

        &.info {
          color: $color-blue;
        }

        &.history {
          color: $color-yellow;
        }

        &.add-green {
          color: $color-green;
        }

        &.repeat {
          color: $color-green;
        }

        &.move {
          color: $color-yellow;
        }

        &.cancel {
          color: $color-red;
        }
      }

      label {
        font-family: Montserrat;
        font-weight: 600;
        font-size: 14px;
        color: #444444;
        text-align: center;
        cursor: pointer;
      }
    }
  }

}

.actions-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 10px;

  div.action {
    flex-grow: 1;
    flex-basis: 1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    border-radius: $border-radius;
    margin: -5px;
    padding: 5px;
    cursor: pointer;
    user-select: none;
    max-width: 120px;

    @include hoverable;

    ion-icon {
      font-size: 24px;

      &.info {
        color: $color-blue;
      }

      &.history {
        color: $color-yellow;
      }

      &.add-green {
        color: $color-green;
      }


      &.repeat {
        color: $color-green;
      }

      &.move {
        color: $color-yellow;
      }

      &.cancel {
        color: $color-red;
      }
    }

    label {
      @include font-header;
      font-size: 14px;
      color: $color-text-2;
      text-align: center;
      cursor: pointer;
    }
  }
}

form {
  ion-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: inherit !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    --background: none;

    ion-item {
      --padding-start: 0;
      --inner-padding-end: 0;
      --highlight-background: var(--ion-border-color);
      --show-full-highlight: 0;
      box-shadow: none;
      --background: transparent;
      --min-height: 44px;

      --background-hover: #FFFFFF;
      --ripple-color: transparent;
      --background-activated-opacity: 0;

      ion-label[position="stacked"] {
        color: var(--color) !important;
        display: contents !important;
        font-style: normal;
        font-weight: 600;
        font-size: 14px !important;
      }

      &.lib-invalid.ion-touched {
        ion-input, ion-textarea, ion-select {
          border-color: $color-red;
        }
      }

      ion-input, ion-textarea, ion-select {
        background: #FAFAFA !important;
        border: $border;
        border-radius: $border-radius;

        margin-top: 5px;
        --padding-start: 10px !important;
        --padding-end: 10px !important;

        caret-color: #000000;
        overflow: hidden; // Чтобы когда автоматом вставился телефон и инпут закрасился, не торчали углы

        font-weight: 500;
        font-size: 14px !important;

        width: 100%;

        &::part(icon) {
          transform: translate3d(0px, 0px, 0px);
          color: black;
        }

        &.ion-invalid.ion-touched {
          border-color: $color-red;
        }
      }

      ion-input, ion-select {
        max-height: 35px;
        min-height: 35px;
      }

      ion-textarea {
        min-height: 80px;
      }

      .date-picker {
        display: flex;
        justify-content: space-between;
        padding-left: 10px !important;
      }

      ion-buttons[slot="end"] {
        margin-inline: 0;

        &.bottom {
          margin: auto 0 0 5px;
        }

        ion-button {
          --padding-top: 0 !important;
          --padding-bottom: 0 !important;
          --padding-end: 0 !important;
          --padding-start: 0 !important;
          width: 36px !important;
          height: 36px !important;

          ion-icon[slot=icon-only] {
            color: $color-text-2;
            font-size: 18px !important;
          }
        }

        button[type="button"] {
          width: 33px !important;
          height: 33px !important;
        }
      }

      &:has(ion-toggle) ion-buttons[slot="end"] {
        margin-top: 0;
      }

      ion-row {
        width: 100%;
        align-items: end;

        ion-col {
          padding: 0;

          ion-select {
            max-width: 100%;
          }
        }
      }
    }
  }
}

.lib-invalid {
  border-color: $color-red;
}

form.ng-submitted {
  ion-input, ion-textarea {
    &.ion-invalid, &.lib-invalid {
      border: 1px solid #F73E32 !important;
    }
  }
}

.hide-mobile {
  @include for-mobile {
    display: none;
  }
}

.hide-desktop {
  @include for-desktop {
    display: none;
  }
}

ion-footer {
  @include for-mobile {
    background: $color-bg;
  }
}

div[id="map"] {
  border-radius: 10px !important;
}

.menu-accordion {
  div.item {
    display: flex;
    gap: 15px;
    font-family: Montserrat, serif;
    font-weight: 600;
    font-size: 14px;
    color: $color-text-3;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 133px;
    }

    &.active {
      color: $color-primary;
    }
  }

  div[slot="content"] {
    background: #{$color-bg};

    div.item {
      padding: 8px 17px;
      cursor: pointer;
      overflow: hidden;

      &:hover {
        background: #ECECEC;
      }
    }

    ion-icon {
      font-size: 20px;
    }
  }

  ion-item[slot="header"] {
    --background: #{$color-bg};

    .ion-accordion-toggle-icon {
      margin-left: 7px;
      font-size: 20px;
    }
  }
}

.bogof {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .item {
    width: 65px;
    height: 65px;
    line-height: 65px;
    border: 2px solid $color-primary;
    border-radius: 50%;
    text-align: center;
    color: #DA4B90;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
  }

  .transparent {
    opacity: 0.5;
    font-weight: 500;
    border-width: 1px;
    //color: $color-text-2;
    //border-color: $color-text-2;
  }

  .price {
    background: linear-gradient(140.65deg, rgba(218, 75, 144, 0.69) 4.51%, #DA4B90 88.04%);
    color: #FFFFFF;
    border-width: 0;
  }
}

ion-list-header {
  & > h3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 15px;

    & > a {
      @include font-main;
      color: $color-text-3;
      font-size: 14px;
      text-decoration: underline;
    }
  }
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
  --swiper-pagination-color: var(--ion-color-primary, #3880ff);
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.5);
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

swiper-slide img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
}

ion-toggle {
  padding: 12px;
}
