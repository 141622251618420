/* Generated by ParaType (http://www.paratype.com)*/
/* Font PT Root UI: Copyright ? ParaType, 2018. All rights reserved.*/

@font-face {
  font-family: 'PTRootUIWeb';
  src:
    url('PT-Root-UI_Light.woff2') format('woff2'),
    url('PT-Root-UI_Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
	font-family: 'PTRootUIWeb';
	src:
		url('PT-Root-UI_Regular.woff2') format('woff2'),
		url('PT-Root-UI_Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
  font-family: 'PTRootUIWeb';
  src:
    url('PT-Root-UI_Medium.woff2') format('woff2'),
    url('PT-Root-UI_Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'PTRootUIWeb';
  src:
    url('PT-Root-UI_Bold.woff2') format('woff2'),
    url('PT-Root-UI_Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
