$font-header: Montserrat;
$font-main: PTRootUIWeb;

$safe-area: 20px;
$cards-space: 10px;
$border-radius: 10px;

$color-primary: #DA4B90;
$color-primary-1: #FEC8E2;
$color-primary-2: #FCE9F2;
$color-primary-3: #FCF3F7;

$color-secondary-1: #E89E0E;
$color-secondary-3: #FFF6DE;

$color-red: #F73E32;
$color-green: #1ABE79;
$color-green-2: #ddf7ec;
$color-blue: #1B9BE2;
$color-blue-2: #ddf3ff;
$color-yellow: #E89E0E;

$color-white: #FFF;
$color-bg: #F6F6F6;
$color-bg-warning: #FFF6DE;

$color-text-1: #1B1B1B;
$color-text-2: #444444;
$color-text-3: #848484;
$color-text-4: #CCCCCC;

$color-line: #EFEFEF;
$color-hover: #ECECEC;

$color-tg: #229ED9;
$color-viber: #8f5db7;

$gradient-primary: linear-gradient(90.93deg, #DA4B90 0.8%, rgba(223, 75, 147, 0.8) 99.79%);

$border: solid 1px $color-line;

@mixin for-mobile {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin for-desktop {
  @media (min-width: 769px) {
    @content;
  }
}

@mixin font-main {
  font-family: $font-main;
  font-weight: 500;
  font-size: 15px;
  line-height: initial;
  color: $color-text-2;
}

@mixin font-small {
  @include font-main;
  font-size: 11px;
}

@mixin font-header {
  font-family: $font-header;
  font-weight: 600;
  color: $color-text-1;
}

@mixin hoverable {
  &:hover {
    background: $color-hover;
  }
}

@mixin box-shadow {
  box-shadow: 0px 1px 3px rgba(169, 169, 169, 0.25);
}

@mixin figure-1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  :first-child {
    flex-shrink: 0;
  }

  :last-child {
    flex-grow: 1;
    overflow: hidden;
  }
}

@mixin ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@mixin ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@mixin ellipsis-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
