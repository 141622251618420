@import "media-breakpoints";

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

h1, h2, h3 {
  margin-top: 32px;
  margin-bottom: 8px;
  font-weight: 500;
}

//h3 {
//  font-weight: 500;
//}

*:not(ion-label) > h2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  & > span {
    flex-grow: 1;
  }

  & > ion-buttons {
    height: 28px;
  }
}

p {
  line-height: 1.4em;
}

a {
  cursor: pointer;
}

ul {
  padding-inline-start: 20px;

  li {
    margin: 8px 0;
  }
}


